import React, { FC, useEffect, useState } from 'react';
import Heading from 'shared/Heading/Heading';
import Nav from 'shared/Nav/Nav';
import NavItem from 'shared/NavItem/NavItem';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import { ReactNode } from 'react';
import appConfig from 'config/appConfig';

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (index: number) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = '',
  heading = '🎈 Latest Articles',
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string, index: number) => {
    onClickTab && onClickTab(index);
    setTabActiveState(item);
  };

  return (
    <div className='flex flex-col mb-8 relative'>
      <Heading desc={subHeading}>{heading}</Heading>
      <div className='flex items-center justify-between'>
        <Nav
          className='sm:space-x-2'
          containerClassName='relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar'
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item, index)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
        <span className='hidden sm:block flex-shrink-0'>
          <ButtonSecondary className='!leading-none'>
            <a href={`${appConfig.URL}search`}>
              <span>Összes</span>
              <i className='ml-3 las la-arrow-right text-xl'></i>
            </a>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;
