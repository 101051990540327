import React, { Fragment, useState, useEffect, FC } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import { PropertiesContext } from 'context/PropertiesContext';
import { getAllProperties } from 'data/DataController';

let sortData = [
  { id: 'date-asc', title: 'Legrégebbiek' },
  { id: 'date-desc', title: 'Legújabbak' },
  { id: 'price-asc', title: 'Ár szerint növekvő' },
  { id: 'price-desc', title: 'Ár szerint csökkenő' },
  { id: 'price-m2-asc', title: 'Négyzetméterár szerint növekvő' },
  { id: 'price-m2-desc', title: 'Négyzetméterár szerint csökkenő' },
  { id: 'size-asc', title: 'Méret szerint növekvő' },
  { id: 'size-desc', title: 'Méret szerint csökkenő' },
  { id: 'plot-size-asc', title: 'Telekméret szerint növekvő' },
  { id: 'plot-size-desc', title: 'Telekméret szerint csökkenő' },
];

// const [bedRef, setBedRef] = useState(null);

const TabFilters = () => {
  const PropertiesContextState = React.useContext(PropertiesContext);
  const [orderByState, setOrderByState] = useState('Rendezés');

  const renderSorting = () => {
    return (
      <div className=''>
        <Popover className='relative'>
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`
           ${open ? '' : ''}
             rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              >
                <span>{`${orderByState}`}</span>
                <ChevronDownIcon
                  className={`${
                    open ? '' : 'text-opacity-70'
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden='true'
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
              >
                <Popover.Panel className='absolute z-10 w-screen max-w-none sm:max-w-[320px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 '>
                  <div className='overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 '>
                    <div className='relative grid gap-8 bg-white dark:bg-neutral-800 p-7 '>
                      {sortData.map((item, id) => (
                        <a
                          key={item.title}
                          onClick={(e) => {
                            /* TODO: call get proeprties overriden the context search query with selected sortby property */
                            e.preventDefault();
                            setOrderByState(item.title);
                            close();
                            getAllProperties({
                              ...PropertiesContextState.searchQuery,
                              page: 1,
                              sortby: item.id,
                            }).then((response) => {
                              PropertiesContextState.setContext({
                                results: response,
                                searchQuery: {
                                  ...PropertiesContextState.searchQuery,
                                  page: 1,
                                  sortby: item.id,
                                },
                                activePropertyTypeId:
                                  PropertiesContextState.activePropertyTypeId,
                              });
                            });
                          }}
                          className='flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                        >
                          <p className='text-sm font-medium '>
                            <>
                              {id % 2 ? (
                                <i className='las la-sort-amount-down'></i>
                              ) : (
                                <i className='las la-sort-amount-up'></i>
                              )}
                              {' ' + item.title}
                            </>
                          </p>
                        </a>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  return (
    <div className='flex justify-end'>
      {/* <div className='hidden lg:flex justify-between space-x-4'> */}
      {/* {renderTabsTypeOfPlace()}
        {renderTabsPriceRage()}
        {renderTabsRoomAndBeds()} */}
      {/* {renderTabMoreFilter()} */}
      {/* </div> */}
      {/* {renderTabMoreFilter()} */}
      <div className='flex items-center px-4 py-2 text-sm rounded-full border text-primary-700 focus:outline-none cursor-pointer'>
        {renderSorting()}
      </div>
    </div>
  );
};

export default TabFilters;
