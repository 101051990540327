import React from 'react';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
//import { getAllProperties } from 'data/DataController';
import appConfig from '../config/appConfig';

type Props = {
  children: React.ReactNode;
};

type Context = {
  // searchTerms: any[];
  searchQuery: object;
  results: any[];
  setContext: Dispatch<SetStateAction<any>>;
  language: string;
  activePropertyTypeId: number;
  // getAllProperties: Function;
};

const initialContext: Context = {
  // searchTerms: [],
  searchQuery: {
    language: '',
    city: '',
    zone: '',
    type: '',
    status: '',
    condition: '',
    bedrooms: '',
    bathrooms: '',
    minprice: '',
    maxprice: '',
    minarea: '',
    maxarea: '',
    showOffers: '',
    extras: '',
    sortby: '',
    page: 1,
  },
  results: [],
  setContext: (): void => {
    throw new Error('setContext function must be overridden');
  },
  language: appConfig.DEFAULTLANG,
  activePropertyTypeId: 0,
};

const PropertiesContext = createContext<any>(initialContext);
//PropertiesContext.displayName = 'Properties Context';

const PropertiesContextProvider = ({ children }: Props): JSX.Element => {
  const [contextState, setContext] = useState<Context>(initialContext);

  // setContext({ ...contextState, language: 'overriden in set context' });

  // console.log('Set Context is just re-rendering!');
  // console.log(contextState.language);

  const [language, setLanguage] = useState(appConfig.DEFAULTLANG);

  // useEffect(() => {
  // function getCookie(key: string) {
  //   var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  //   return b ? b.pop() : '';
  // }
  // const siteLang = getCookie('siteLang');
  // if (siteLang !== undefined && siteLang !== '') {
  //   /* @ts-ignore */
  //   setLanguage(siteLang);
  //   console.log('Site lang cookie is set to: ' + siteLang);
  //   setContext({ ...contextState, language: siteLang });
  // } else {
  //   console.log(
  //     'Site lang cookie not found, site lang set to default: ' +
  //       appConfig.DEFAULTLANG
  //   );
  //   setLanguage(appConfig.DEFAULTLANG);
  //   setContext({ ...contextState, language: appConfig.DEFAULTLANG });
  // }
  // }, []);

  //setContext({ ...contextState, language: 'overriden in set context' });

  // const getProperties = () => {
  //   /* @ts-ignore */
  //   //return getAllProperties(contextState.searchTerms);
  //   getAllProperties(
  //     'en',
  //     [],
  //     [],
  //     [],
  //     [],
  //     [],
  //     1,
  //     1,
  //     [],
  //     [],
  //     [],
  //     [],
  //     false,
  //     []
  //     //1
  //   ).then((response) => {
  //     console.log(contextState.results);
  //     console.log('CONTEXT REPSONSE!' + response);
  //     contextState.results = response;
  //     console.log(contextState.results);
  //     //console.log('Get proeprties for city: ');
  //     //console.log(city);
  //   });
  // };

  return (
    <PropertiesContext.Provider
      value={{ ...contextState, setContext /*getProperties*/ }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

export { PropertiesContext, PropertiesContextProvider };

// import React from 'react';

// const initialState = {
//   authState: {
//     searchTerms: [],
//     results: [],
//   },
//   authActions: {
//     setEmail: () => {},
//     setPassword: () => {},
//   },
// };

// const PropertiesContext = React.createContext(initialState);

// export default PropertiesContext;
