// @ts-nocheck
//import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from 'react';
import Checkbox from 'components/HeroSearchForm/CheckBoxSimple';

export interface Props {
  onClick?: () => void;
  onChange?: (value: string, id: number) => void;
  onChangeSelectedCity?: (allCity: Array<object>) => void;
  onChangeSelectedDistrict?: (
    allDistrict: Array<object>,
    selectedValues: Array<number>
  ) => void;
  onChangeSelectedCounty?: (
    allCounty: Array<object>,
    selectedValues: Array<number>
  ) => void;
  resetLocationFilter: () => void;
  className?: string;
  defaultValue?: string;
  defaultCities?: any[];
  defaultDistricts?: any[];
  defaultCounties?: any[];
  headingText?: string;
  suggestions?: any[];
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  onChangeSelectedCity,
  onChangeSelectedDistrict,
  onChangeSelectedCounty,
  resetLocationFilter,
  className = '',
  defaultValue,
  defaultCities,
  defaultDistricts,
  defaultCounties,
  suggestions,
  headingText = 'Merre keresnél ?',
}) => {
  const [value, setValue] = useState('');
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const [isCountySearch, setIsCountySearch] = useState(false);

  // const [selectedCitiesText, setSelectedCitiesText] = useState([]);
  // const [selectedCityIDs, setSelectedCityIDs] = useState([]);
  const [selectedCities, setSelectedCities] = useState(defaultCities || []);
  //const [selectedCityIDs, setSelectedCityIDs] = useState([]);

  const defaultCounty = [];
  const [allCounty, setAllCounty] = useState(defaultCounties || []);
  const [selectedCounty, setSelectedCounty] = useState(defaultCounty);

  const defaultDistrict = [];
  const [allDistrict, setAllDistrict] = useState(defaultDistricts || []);
  const [selectedDistrict, setSelectedDistrict] =
    // @ts-ignore
    useState(defaultDistrict);

  const [filteredSuggestions, setFilteredSuggestions] = useState(['']);
  const [input, setInput] = useState('');
  const [citySuggestions, setCitySuggestions] = useState(
    suggestions.map(function (item: any) {
      return item['cityName'];
    })
  );

  useEffect(() => {
    setCitySuggestions(
      suggestions
        .filter((item, idx) => idx < 5)
        .map(function (item: any) {
          return item['cityName'];
        })
    );
  }, [suggestions]);

  // useEffect(() => {
  //   console.log('Default counties try to change: ');
  //   console.log(defaultCounties);
  //   if (!defaultCounties) return;
  //   setAllCounty(defaultCounties);
  // }, [defaultCounties]);

  useEffect(() => {
    setSelectedCities(defaultCities);
  }, [defaultCities]);

  useEffect(() => {
    if (!defaultDistricts) return;
    setAllDistrict(defaultDistricts);
    if (defaultDistricts.length === 0) return;

    const onlyCheckedValues = defaultDistricts.reduce(
      // @ts-ignore
      (previousValue, currentValue) => {
        //   console.log(currentValue.checked);
        if (currentValue.checked === true) {
          return [...previousValue, currentValue.id];
        }
        return previousValue;
      },
      []
    );

    setSelectedDistrict(onlyCheckedValues);
  }, [defaultDistricts]);

  useEffect(() => {
    if (!defaultCounties) return;
    setAllCounty(defaultCounties);
    if (defaultCounties.length === 0) return;

    const onlyCheckedValues = defaultCounties.reduce(
      // @ts-ignore
      (previousValue, currentValue) => {
        //   console.log(currentValue.checked);
        if (currentValue.checked === true) {
          return [...previousValue, currentValue.id];
        }
        return previousValue;
      },
      []
    );

    setSelectedCounty(onlyCheckedValues);
  }, [defaultCounties]);

  // useEffect(() => {
  //   getCounties().then((response: any) => {
  //     setAllCounty(response);
  //   });

  //   getDistricts().then((response: any) => {
  //     setAllDistrict(response);
  //   });
  // }, []);

  useEffect(() => {
    onChangeSelectedCity(selectedCities);
    console.log('---> LocatioInputMobile citys from prop');
    console.log(selectedCities);
    // console.log(selectedCityIDs);
  }, [selectedCities]);

  useEffect(() => {
    onChangeSelectedDistrict(allDistrict, selectedDistrict);
  }, [selectedDistrict]);

  useEffect(() => {
    onChangeSelectedCounty(allCounty, selectedCounty);
  }, [selectedCounty]);

  // useEffect(() => {
  //   const selectedCityIDs = selectedCities.map(function (item: any) {
  //     return item['cityId'];
  //   });
  //   onChangeSelectedCity(selectedCityIDs);
  // }, [selectedCities]);

  // useEffect(() => {
  //   onChangeSelectedDistrict(selectedDistrict);
  // }, [selectedDistrict]);

  // useEffect(() => {
  //   onChangeSelectedCounty(selectedCounty);
  // }, [selectedCounty]);

  const handleSelectLocation = (cityName: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      //setValue(cityName);
      setValue('');

      // Get the city ID from the original suggstions list
      const choosenCityId = suggestions.filter((city) => {
        //console.log(cityName['cityName']);
        return city['cityName'] === cityName;
      });
      if (choosenCityId[0]) {
        const cityID = choosenCityId[0]['cityId'];
        //onChange && onChange(cityName, cityID);

        let locationAlreadySelected = selectedCities.filter(
          (selectedLocation) => selectedLocation['cityId'] === cityID
        );

        let isLocationAlreadySelected = locationAlreadySelected.length !== 0;

        if (!isLocationAlreadySelected) {
          setSelectedCities((prevSelectedCities) => [
            ...prevSelectedCities,
            { cityId: cityID, cityName: cityName },
          ]);

          // setSelectedCityIDs((prevSelectedCityIDs) => [
          //   ...prevSelectedCityIDs,
          //   cityID,
          // ]);
        }
      }
    }, 0);
  };

  const onChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;

    if (suggestions) {
      let citySuggestions = suggestions.map(function (item: any) {
        return item['cityName'];
      });

      // Filter our suggestions that don't contain the user's input
      const unLinked = citySuggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setInput(e.currentTarget.value);
      setValue(e.currentTarget.value);
      setFilteredSuggestions(unLinked);
    }
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: string[];
  }) => {
    return (
      <>
        <p className='block font-semibold text-base'>
          {heading || 'Destinations'}
        </p>
        <div className='flex flex-col my-3'>
          {items.map((item) => {
            return (
              <div
                className='py-2 mb-1 flex inline-block items-center space-x-3 text-sm w-1/3 rounded-xl'
                onClick={() => handleSelectLocation(item)}
                key={item}
              >
                {/* <MapPinIcon className='w-5 h-5 text-neutral-500 dark:text-neutral-400' /> */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z'
                  />
                </svg>
                <span className=''>{item}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderSelectedLocationsWithClose = (
    locationID: number,
    locationName: string
  ) => {
    return (
      <div
        className={
          'flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all border-primary-500 bg-primary-50 text-primary-700 suggestionIdentifier'
        }
        onClick={() => {
          setSelectedCities((prevState) =>
            prevState.filter((prevItem) => prevItem.cityId !== locationID)
          );
        }}
      >
        <span className={'suggestionIdentifier'}>{locationName}</span>
        {renderXClear()}
      </div>
    );
  };

  const renderSelectedDistrictsWithClose = (
    districtID: number,
    districtName: string
  ) => {
    return (
      <div
        className={
          'flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all border-primary-500 bg-primary-50 text-primary-700 suggestionIdentifier'
        }
        onClick={() => {
          setAllDistrict((prevState) =>
            prevState.map((district) =>
              district.id === districtID
                ? { id: district.id, title: district.title, checked: false }
                : district
            )
          );
          setSelectedDistrict((prevState) =>
            prevState.filter((prevItem) => prevItem !== districtID)
          );

          // onChangeSelectedDistrict((prevState) =>
          //   prevState.filter((prevItem) => prevItem !== districtID)
          // );
        }}
      >
        <span className={'suggestionIdentifier'}>{districtName}</span>
        {renderXClear()}
      </div>
    );
  };

  const renderSelectedCountiesWithClose = (
    countyID: number,
    countyName: string
  ) => {
    return (
      <div
        className={
          'flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all border-primary-500 bg-primary-50 text-primary-700 suggestionIdentifier'
        }
        onClick={() => {
          setAllCounty((prevState) =>
            prevState.map((county) =>
              county.id === countyID
                ? { id: county.id, title: county.title, checked: false }
                : county
            )
          );

          setSelectedCounty((prevState) =>
            prevState.filter((prevItem) => prevItem !== countyID)
          );
        }}
      >
        <span className={'suggestionIdentifier'}>{countyName}</span>
        {renderXClear()}
      </div>
    );
  };

  const renderDeletelocationSearch = () => {
    return (
      <div
        className={
          'flex items-center justify-center text-red-500 hover:text-red-600 px-4 py-2 text-sm cursor-pointer font-bold suggestionIdentifier'
        }
        onClick={() => {
          setSelectedCities([]);
          setSelectedCounty([]);
          setSelectedDistrict([]);
          setAllDistrict((prevState) =>
            prevState.map((district) =>
              district.checked === true
                ? { id: district.id, title: district.title, cheked: false }
                : district
            )
          );
          setAllCounty((prevState) =>
            prevState.map((county) =>
              county.checked === true
                ? { id: county.id, title: county.title, checked: false }
                : county
            )
          );

          resetLocationFilter();
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6 suggestionIdentifier'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
          />
        </svg>

        <span className='ml-2 suggestionIdentifier'>
          {'Választottak törlése'}
        </span>
      </div>
    );
  };

  const renderXClear = () => {
    return (
      <span className='w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer suggestionIdentifier'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-3 w-3 suggestionIdentifier'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
            clipRule='evenodd'
            className='suggestionIdentifier'
          />
        </svg>
      </span>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className='p-5'>
        <span className='block font-semibold text-xl sm:text-2xl'>
          {headingText}
        </span>
        <div className='relative mt-5'>
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={'Keresés városok szerint'}
            value={value}
            // onChange={(e) => setValue(e.currentTarget.value)}
            onChange={(e) => onChangeInput(e)}
            ref={inputRef}
          />
          <span className='absolute right-2.5 top-1/2 -translate-y-1/2'>
            {/* <MagnifyingGlassIcon className='w-5 h-5 text-neutral-700 dark:text-neutral-400' /> */}
          </span>
        </div>
        <div className='mt-7'>
          {value
            ? renderSearchValues({
                heading: 'Városok',
                items: filteredSuggestions,
              })
            : renderSearchValues({
                heading: 'Legnépszerűbb városok',
                items: citySuggestions,
              })}
        </div>
        <div className='flex flex-wrap gap-4'>
          {/* {selectedCitiesText.map((item) =>
                renderSelectedLocationsWithClose(item)
              )} */}
          {/* {console.log('==========>>>>>>>>>>>>>>>>>>>>>>>>>')}
          {console.log(selectedCities)} */}
          {selectedCities.length !== 0 &&
            selectedCities.map(
              (item) =>
                item.cityId &&
                renderSelectedLocationsWithClose(item.cityId, item.cityName)
            )}
          {allDistrict.length !== 0 &&
            allDistrict.map((item) => {
              if (item.checked !== true) {
                return;
              }
              return renderSelectedDistrictsWithClose(item.id, item.title);
            })}
          {allCounty.length !== 0 &&
            allCounty.map((item) => {
              if (item.checked !== true) {
                return;
              }
              return renderSelectedCountiesWithClose(item.id, item.title);
            })}
        </div>
        <div className='flex'>
          {((selectedCities && selectedCities.length !== 0) ||
            selectedDistrict.length !== 0 ||
            selectedCounty.length !== 0) &&
            renderDeletelocationSearch()}
        </div>

        <div className='p-5 rounded-xl bg-neutral-100'>
          <div className='pt-2 pb-2 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8'>
            <div className='relative focus:outline-none focus-visible:ring-0 outline-none select-none'>
              <div
                className={`${
                  !isCountySearch
                    ? 'px-5 py-2 bg-white rounded-full text-black dark:text-white'
                    : 'px-5 py-2'
                }  `}
                onClick={() => {
                  setIsCountySearch(false);
                }}
              >
                Kerületek
              </div>
            </div>
            <div className='relative focus:outline-none focus-visible:ring-0 outline-none select-none'>
              <div
                className={`${
                  isCountySearch
                    ? 'px-5 py-2 bg-white rounded-full text-black dark:text-white'
                    : 'px-5 py-2'
                }  `}
                onClick={() => {
                  setIsCountySearch(true);
                }}
              >
                Megyék
              </div>
            </div>
          </div>

          <div>
            {!isCountySearch && (
              <div className='p-5 bg-white rounded-xl'>
                <div className='relative flex flex-col space-y-5 mt-7'>
                  <div className='grid grid-cols-2 sm:grid-cols-3 gap-4'>
                    {allDistrict?.map((item, index) => (
                      <div key={item.index} className=''>
                        <Checkbox
                          key={item.index}
                          name={item.title}
                          label={item.title}
                          value={selectedDistrict?.includes(
                            // @ts-ignore
                            allDistrict[index].id
                          )}
                          // value={item.checked}
                          onChange={(e) => {
                            const newState = allDistrict.map((item, i) => {
                              if (i === index) {
                                return { ...item, checked: e };
                              }
                              return item;
                            });

                            const onlyCheckedValues = newState.reduce(
                              // @ts-ignore
                              (previousValue, currentValue) => {
                                //   console.log(currentValue.checked);
                                if (currentValue.checked === true) {
                                  return [...previousValue, currentValue.id];
                                }
                                return previousValue;
                              },
                              []
                            );
                            setAllDistrict(newState);
                            // setSelectedDistrict(onlyCheckedValues);
                            onChangeSelectedDistrict(
                              newState,
                              onlyCheckedValues
                            );
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {isCountySearch && (
            <div className='p-5 bg-white rounded-xl'>
              <div className='relative flex flex-col space-y-5 mt-7'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                  {allCounty?.map((item, index) => (
                    <div key={item.id}>
                      <Checkbox
                        name={item.title}
                        label={item.title}
                        value={selectedCounty?.includes(
                          // @ts-ignore
                          allCounty[index].id
                        )}
                        onChange={(e) => {
                          const newState = allCounty.map((item, i) => {
                            if (i === index) {
                              return { ...item, checked: e };
                            }
                            return item;
                          });

                          const onlyCheckedValues = newState.reduce(
                            // @ts-ignore
                            (previousValue, currentValue) => {
                              //   console.log(currentValue.checked);
                              if (currentValue.checked === true) {
                                return [...previousValue, currentValue.id];
                              }
                              return previousValue;
                            },
                            []
                          );
                          setAllCounty(newState);
                          setSelectedCounty(onlyCheckedValues);

                          onChangeSelectedCounty(newState, onlyCheckedValues);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
