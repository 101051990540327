import React, { useEffect } from 'react';
import { FC } from 'react';
import Checkbox from 'shared/Checkbox/Checkbox';

// DEMO DATA
export interface MultipleSelect {
  id: number;
  title: string;
  name: string;
  description: string;
  checked: boolean;
}

export interface MultipleSelectProps {
  onChange?: (data: MultipleSelect[], arg1: any) => void;
  defaultValue?: MultipleSelect[];
  sectionTitle?: string;
}

const MultipleSelect: FC<MultipleSelectProps> = ({
  onChange,
  defaultValue,
  sectionTitle,
}) => {
  const [condition, setCondition] = React.useState<MultipleSelect[]>(
    defaultValue || []
  );

  useEffect(() => {
    if (!defaultValue) return;
    setCondition(defaultValue);
  }, [defaultValue]);

  return (
    <div className='p-5'>
      <span className='block font-semibold text-xl sm:text-2xl'>
        {sectionTitle}
      </span>

      <div className='relative flex flex-col space-y-5 mt-7'>
        <div className='grid grid-cols-2 gap-6'>
          {condition?.map((item, index) => (
            <div key={item.id} className=''>
              <Checkbox
                name={item.title}
                label={item.title}
                subLabel={item.description}
                defaultChecked={condition[index].checked}
                onChange={(e) => {
                  const newState = condition.map((item, i) => {
                    if (i === index) {
                      return { ...item, checked: e };
                    }
                    return item;
                  });
                  setCondition(() => {
                    return newState;
                  });
                  // const onlyCheckedValues = newState.map((item, index) => {
                  //   if (item.checked !== true) {
                  //     return;
                  //   } else {
                  //     return { ...item, item };
                  //   }
                  // });

                  const onlyCheckedValues = newState.reduce(
                    // @ts-ignore
                    (previousValue, currentValue) => {
                      // console.log(currentValue.checked);
                      if (currentValue.checked === true) {
                        return [...previousValue, currentValue.id];
                      }
                      return previousValue;
                    },
                    []
                  );

                  onChange && onChange(newState, onlyCheckedValues);
                  // console.log('==========');
                  // console.log(newState);
                  // console.log(onlyCheckedValues);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultipleSelect;
