// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { FC } from 'react';
// import Slider from 'rc-slider';
import { Range } from 'rc-slider';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface AreaRangeInputProps {
  onChange?: (e: number[]) => void;
  defaultValue?: number[];
  sectionTitle: string;
  minMaxDescription: string;
  rangeMinValue: number;
  rangeMaxValue: number;
  unit?: string;
}

const AreaRangeInput: FC<AreaRangeInputProps> = ({
  onChange,
  defaultValue,
  sectionTitle,
  minMaxDescription,
  rangeMinValue,
  rangeMaxValue,
  unit = 'm²',
}) => {
  const [rangeArea, setRangeArea] = useState(defaultValue);

  useEffect(() => {
    setRangeArea(defaultValue);
  }, []);

  const setRangeAreaHandler = () => {
    /* @ts-ignore */
    onChange(rangeArea);
  };

  // Its ugly but for performance -> If theres a range slide -> a lot state upgrade, a lot re-render
  // Theres no onAfterChange event on manual input -> It's needed
  const setRangeAreaHandlerWithValue = (rangeArea: number[]) => {
    /* @ts-ignore */
    onChange(rangeArea);
  };

  const minValue = 0;
  const maxValue = 5000000;

  return (
    <div className='p-5'>
      <h3 className='text-xl font-medium'>{sectionTitle}</h3>
      <div className='mt-6 relative '>
        <div className='relative flex flex-col space-y-8'>
          <div className='space-y-5'>
            <Range
              className='text-red-400'
              min={rangeMinValue}
              max={rangeMaxValue}
              defaultValue={[rangeArea[0], rangeArea[1]]}
              value={[rangeArea[0], rangeArea[1]]}
              allowCross={false}
              step={1}
              //   onChange={setRangeArea}
              onChange={(e) => {
                setRangeArea(e as number[]);
                onChange && onChange(e as number[]);
              }}
              onAfterChange={setRangeAreaHandler}
            />
          </div>

          <div className='flex justify-between space-x-5'>
            <div>
              <label
                htmlFor='minArea'
                className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
              >
                {`Min ${minMaxDescription}`}
              </label>
              <div className='mt-1 relative rounded-md'>
                <div className='absolute inset-y-0 right-3 pl-3 flex items-center pointer-events-none'>
                  <span className='text-neutral-500 sm:text-sm'>{unit}</span>
                </div>
                <input
                  type='number'
                  name='minArea'
                  id='minArea'
                  className='focus:ring-primary-500 focus:border-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                  value={rangeArea[0]}
                  onChange={(e) => {
                    if (
                      minValue < Number(e.target.value) &&
                      Number(e.target.value) < maxValue
                    ) {
                      setRangeArea([parseInt(e.target.value), rangeArea[1]]);
                      setRangeAreaHandlerWithValue([
                        parseInt(e.target.value),
                        rangeArea[1],
                      ]);
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor='maxArea'
                className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
              >
                {`Max ${minMaxDescription}`}
              </label>
              <div className='mt-1 relative rounded-md'>
                <div className='absolute inset-y-0 right-3 pl-3 flex items-center pointer-events-none'>
                  <span className='text-neutral-500 sm:text-sm'>{unit}</span>
                </div>
                <input
                  type='number'
                  name='maxArea'
                  id='maxArea'
                  className='focus:ring-primary-500 focus:border-priring-primary-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                  value={rangeArea[1]}
                  onChange={(e) => {
                    if (
                      minValue < Number(e.target.value) &&
                      Number(e.target.value) < maxValue
                    ) {
                      setRangeArea([rangeArea[0], parseInt(e.target.value)]);
                      setRangeAreaHandlerWithValue([
                        rangeArea[0],
                        parseInt(e.target.value),
                      ]);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaRangeInput;
