import React, { Fragment, useState, useEffect, useRef, FC } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonThird from 'shared/Button/ButtonThird';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonSubmit from './ButtonSubmit';
import LocationInput from './LocationInput';
import PropertyTypeSelect from './PropertyTypeSelect';
import PriceRangeInput from './PriceRangeInput';
import { ChevronDownIcon } from '@heroicons/react/solid';
import convertNumbThousand from 'utils/convertNumbThousand';
import LocationInputMobile from 'components/HeroSearchMobile/LocationInputMobile';
import PropertyTypeSelectMobile from 'components/HeroSearchMobile/PropertyTypeSelectMobile';
import PriceRangeInputMobile from 'components/HeroSearchMobile/PriceRangeInputMobile';
import AreaRangeInput from 'components/HeroSearchMobile/AreaRangeInputMobile';
import MultipleSelect from 'components/HeroSearchMobile/MultipleSelectCheckbox';
import ButtonCircle from 'shared/Button/ButtonCircle';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';
import ButtonSearch from 'shared/Button/ButtonSearch';
import HeroSearchMobile from 'components/HeroSearchMobile/HeroSearchMobile';
import useWindowSize from 'hooks/useWindowResize';
import TabFilters from 'containers/ListingRealEstatePage/TabFilters';

import { PropertiesContext } from 'context/PropertiesContext';
import {
  getCities,
  getCounties,
  getDistricts,
  getTypes,
  getConditions,
  getAllPropertyType,
  getAllProperties,
  getHeatings,
  getWallTypes,
  getMoveIn,
  getParking,
  getView,
  getFurnished,
  getFloorLocated,
  getElectricity,
  getWater,
  getGas,
  getCanal,
  getExtras,
  getBathrooms,
} from 'data/DataController';

export type SearchRealEstateTab = 'Vásárlás' | 'Bérlés';

export interface RealEstateSearchFormProps {
  className?: string;
  currentTab?: SearchRealEstateTab;
  haveDefaultValue?: boolean;
  language?: string;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = 'Plattensee';

const RealEstateSearchForm: FC<RealEstateSearchFormProps> = ({
  className = '',
  currentTab = 'Vásárlás',
  haveDefaultValue = false,
}) => {
  const windowSize = useWindowSize();
  const mobileMinWidthBreakpoint = 768;

  const popupRef = useRef(null);

  const scrollToTopInsidePopup = () => {
    // @ts-ignore
    popupRef?.current.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  const firstChoosePropertyType = () => {
    return (
      <div className='relative flex flex-col space-y-5 mt-7'>
        <span>{'Először válassz ingatlantípust'}</span>
        <div>
          <ButtonPrimary
            onClick={() => {
              setFieldNameShow('propertyType');
              scrollToTopInsidePopup();
            }}
            sizeClass='px-20 py-2 sm:px-5'
          >
            {'Tipus választása'}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6 ml-2'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M4.5 15.75l7.5-7.5 7.5 7.5'
              />
            </svg>
          </ButtonPrimary>
        </div>
      </div>
    );
  };

  const tabs: SearchRealEstateTab[] = ['Vásárlás', 'Bérlés'];
  const [tabActive, setTabActive] = useState<SearchRealEstateTab>(currentTab);
  //const [locationInputValue, setLocationInputValue] = useState('');
  //const [isLoaded, setIsLoaded] = useState(true);

  const PropertiesContextState = React.useContext(PropertiesContext);

  const defaultStatus = 0;
  // @ts-ignore
  const defaultRangePrices = [0, 300];
  const defaultPriceMultiplier = 1000000;
  const defaultRangeBalcony = [0, 30];
  const defaultRangeTerrace = [0, 50];
  const defaultRangePlot = [0, 10000000];
  const defaultRangeSquarePrice = [0, 1500000];
  const defaultRangeConstructionYear = [0, new Date().getFullYear()];
  const defaultRangeInnerHeight = [0, 400];
  const defaultSelectedPropertyType = 0;
  const defaultMinArea = 0;
  const defaultMaxArea = 300;
  const defaultBedrooms = 0;
  const defaultBathrooms = 0;
  const defaultFloor = 0;
  // @ts-ignore
  const defaultConditions = [];
  // @ts-ignore
  const defaultSubCategories = [];
  // @ts-ignore
  const defaultHeating = [];
  // @ts-ignore
  const defaultWallTypes = [];
  // @ts-ignore
  const defaultMoveIn = [];
  // @ts-ignore
  const defaultParking = [];
  // @ts-ignore
  const defaultView = [];
  // @ts-ignore
  const defaultFurnished = [];
  // @ts-ignore
  const defaultFloorLocated = [];
  // @ts-ignore
  const defaultElectricity = [];
  // @ts-ignore
  const defaultWater = [];
  // @ts-ignore
  const defaultGas = [];
  // @ts-ignore
  const defaultCanal = [];
  // @ts-ignore
  const defaultExtras = [];
  // @ts-ignore
  const defaultBathroomTypes = [];

  const showElectricityAtID = [0, 3, 4];
  const showWaterAtID = [0, 3, 4];
  const showGasAtID = [0, 3, 4];
  const showCanalAtID = [0, 3, 4];
  const showBedBathsAtID = [0, 1, 2, 4, 6];
  const showBalconyAreaAtID = [0, 1, 2, 4, 5, 6];
  const showTerraceAreaAtID = [0, 2, 4, 6];
  const showPlotAreaAtID = [0, 2, 3, 4, 5, 6, 7];
  const showInnerHeightAtID = [0, 1, 2, 4, 5, 6];
  const showHeatingAtID = [0, 1, 2, 4, 5, 6];
  const showConditionAtID = [0, 1, 2, 4, 5, 6];
  const showBuiltYearAtID = [0, 1, 2, 4, 5, 6, 7];
  const showMoveInAtID = [0, 1, 2, 4, 5, 6, 7];
  const showViewAtID = [0, 1, 2, 4, 5, 6];
  const showFurnishedAtID = [0, 1, 2, 4, 5, 6];
  const showFloorAtID = [0, 2, 4, 5, 6];
  const showFloorLocatedAtID = [0, 1];
  const showWallTypeAtID = [0, 1, 2, 4, 5, 6];
  const showExtrasAtID = [0, 1, 2, 4, 6];
  const showBathroomTypesAtID = [0, 1, 2, 4, 6];

  const [fieldNameShow, setFieldNameShow] = useState<
    | 'location'
    | 'propertyType'
    | 'price'
    | 'price_m2'
    | 'area'
    | 'balconyarea'
    | 'terracearea'
    | 'plotarea'
    | 'condition'
    | 'bedbath'
    | 'floor'
    | 'floor_located'
    | 'propertysub'
    | 'heatings'
    | 'walls'
    | 'construction_year'
    | 'inner_height'
    | 'move_in'
    | 'parking'
    | 'view'
    | 'furnished'
    | 'electricity'
    | 'water'
    | 'gas'
    | 'canal'
    | 'extras'
    | 'bathtype'
    | 'nothing'
  >('propertyType');

  // Translations
  // const contextState = React.useContext(LanguageContext);
  // const language = contextState.language;
  // const Strings = Languages[language].texts;

  const [isMobile, setIsmObile] = useState(false);

  useEffect(() => {
    setIsmObile(windowSize.width <= mobileMinWidthBreakpoint);
  }, [windowSize]);

  const [language, setLanguage] = useState('hu');

  useEffect(() => {
    function getCookie(key: string) {
      var b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
      return b ? b.pop() : '';
    }

    if (!getCookie('siteLang')) {
      setLanguage('hu');
    } else {
      /* @ts-ignore */
      setLanguage(getCookie('siteLang'));
      console.log('Site lang cookie is set to: ' + getCookie('siteLang'));
    }
  }, []);

  //TODO: get grom prop and onchange useeffect listener, conditional rendering price
  const [isRent, setIsRent] = useState(false);

  // buy or rent ID for API call
  const [status, setStatus] = useState(1);

  const [selectedCityObj, setSelectedCityObj] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  //const [selectedCityIDs, setSelectedCityIDs] = useState([]);
  const [cityText, setCityText] = useState('');
  const [cities, setCities] = useState([]);

  const [allDistrict, setAllDistrict] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);

  const [allCounty, setAllCounty] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState([]);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(0);
  const [selectedPropertyTypeText, setSelectedPropertyTypeText] = useState('');

  const [rangePrices, setRangePrices] = useState(
    !isRent ? [0, 300] : [10000, 1000000]
  );

  const [rangeSquarePrices, setRangeSquarePrices] = useState([0, 1000000]);

  const [rangeArea, setRangeArea] = useState([0, 300]);

  const [rangeBalconyArea, setRangeBalconyArea] = useState([0, 30]);
  const [rangeTerraceArea, setRangeTerraceArea] = useState([0, 50]);
  const [rangePlotArea, setRangePlotArea] = useState([0, 10000]);

  // All checkbox data
  const [condition, setCondition] = useState([]);
  // ID's for API call
  const [selectedConditions, setSelectedConditions] =
    // @ts-ignore
    useState(defaultConditions);

  const [allPropertySubtype, setAllPropertySubtype] = useState([]);
  const [selectedPropertySubtypes, setSelectedPropertySubtypes] =
    // @ts-ignore
    useState(defaultSubCategories);

  const [allHeating, setAllHeating] = useState([]);
  const [selectedHeating, setSelectedHeating] =
    // @ts-ignore
    useState(defaultHeating);

  const [allWallType, setAllWallType] = useState([]);
  const [selectedWallTypes, setSelectedWallTypes] =
    // @ts-ignore
    useState(defaultWallTypes);

  const [allMoveIn, setAllMoveIn] = useState([]);
  const [selectedMoveIn, setSelectedMoveIn] =
    // @ts-ignore
    useState(defaultMoveIn);

  const [allParking, setAllParking] = useState([]);
  const [selectedParking, setSelectedParking] =
    // @ts-ignore
    useState(defaultParking);

  const [allView, setAllView] = useState([]);
  const [selectedView, setSelectedView] =
    // @ts-ignore
    useState(defaultView);

  const [allFurnished, setAllFurnished] = useState([]);
  const [selectedFurnished, setSelectedFurnished] =
    // @ts-ignore
    useState(defaultFurnished);

  const [allFloorLocated, setAllFloorLocated] = useState([]);
  const [selectedFloorLocated, setSelectedFloorLocated] =
    // @ts-ignore
    useState(defaultFloorLocated);

  const [allElectricity, setAllElectricity] = useState([]);
  const [selectedElectricity, setSelectedElectricity] =
    // @ts-ignore
    useState(defaultElectricity);

  const [allWater, setAllWater] = useState([]);
  const [selectedWater, setSelectedWater] =
    // @ts-ignore
    useState(defaultWater);

  const [allGas, setAllGas] = useState([]);
  const [selectedGas, setSelectedGas] =
    // @ts-ignore
    useState(defaultGas);

  const [allCanal, setAllCanal] = useState([]);
  const [selectedCanal, setSelectedCanal] =
    // @ts-ignore
    useState(defaultCanal);

  const [extras, setExtras] = useState([]);
  const [bathroomtypes, setBathroomTypes] = useState([]);

  const [bedrooms, setBedrooms] = useState(defaultBedrooms);
  const [bathrooms, setBathrooms] = useState(defaultBathrooms);
  const [floors, setFloors] = useState(defaultFloor);
  const [rangeConstructionYear, setRangeConstructionYear] = useState(
    defaultRangeConstructionYear
  );

  const [rangeInnerHeight, setRangeInnerHeight] = useState(
    defaultRangeInnerHeight
  );

  // @ts-ignore
  const [selectedExtras, setSelectedExtras] = useState(defaultExtras);
  // @ts-ignore
  const [selectedBathroomTypes, setSelectedBathroomtypes] =
    // @ts-ignore
    useState(defaultBathroomTypes);
  const [isFilteringActive, setIsFilteringActive] = useState(false);

  const getIdForPropertyTypeSearch = (key: any, array: any) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].indexOf(key) == 0) {
        console.log('matching---> ');
        console.log(i);
        /* search query for all (both) */
        if (i === 0) {
          return 'any';
        }
        return i;
      }
    }
    return 'any';
  };

  const [priceMultiplier, setPriceMultiplier] = useState(!isRent ? 1000000 : 1);

  useEffect(() => {
    setStatus(isRent ? 2 : 1);
    // @ts-ignore
    setTabActive(isRent ? 'Bérlés' : 'Vásárlás');
    setPriceMultiplier(isRent ? 1 : 1000000);
    setRangePrices(isRent ? [10000, 1000000] : [0, 300]);
  }, [isRent]);

  useEffect(() => {
    getCities(language).then((response: any) => {
      const citySuggestions: any = response.map(function (item: any) {
        return { cityId: item['id'], cityName: item['title'] };
      });
      //console.log(citySuggestions);;
      setCities(citySuggestions);
      //setCityIds(cityIdSuggestions);
    });
  }, []);

  useEffect(() => {
    getDistricts().then((response: any) => {
      setAllDistrict(response);
    });

    getCounties().then((response: any) => {
      setAllCounty(response);
      console.log('All county in Searchfrom setted to: ');
      console.log(response);
    });
  }, []);

  useEffect(() => {
    getTypes(language).then((response: any) => {
      // console.log(response);
      setPropertyTypes(response);
    });
  }, []);

  useEffect(() => {
    getConditions(language).then((response: any) => {
      // console.log(response);
      setCondition(response);

      // console.log('conditions setted with response: ');
      // console.log(response);
    });
  }, [language]);

  useEffect(() => {
    getExtras(language).then((response: any) => {
      // console.log(response);
      setExtras(response);

      // console.log('extras setted with: ');
      // console.log(response);
    });
  }, [language]);

  useEffect(() => {
    getBathrooms(language).then((response: any) => {
      // console.log(response);
      setBathroomTypes(response);

      // console.log('Bathroom types setted with: ');
      // console.log(response);
    });
  }, [language]);

  useEffect(() => {
    if (language && selectedPropertyType) {
      getAllPropertyType(language, selectedPropertyType).then(
        (response: any) => {
          setAllPropertySubtype(response);

          // console.log('Get all property type: ');
          // console.log(response);
        }
      );
    }
  }, [language, selectedPropertyType]);

  useEffect(() => {
    if (PropertiesContextState.activePropertyTypeId) {
      // console.log(
      //   'Active proeprty changed to:' +
      //     PropertiesContextState.activePropertyTypeId
      // );
      // @ts-ignore
      setSelectedPropertySubtypes(defaultSubCategories);
      // @ts-ignore
      setSelectedWallTypes([]);

      PropertiesContextState.setContext({
        results: PropertiesContextState.results,
        searchQuery: {
          ...PropertiesContextState.searchQuery,
          all_type: [],
          heating: [],
          wall_type: [],
          bathroom_type: [],
        },
        activePropertyTypeId: PropertiesContextState.activePropertyTypeId,
      });

      // resetFilters();
      // applyFilters();
    }
  }, [PropertiesContextState.activePropertyTypeId]);

  useEffect(() => {
    getHeatings(language).then((response: any) => {
      // console.log(response);
      setAllHeating(response);
    });
  }, [language]);

  useEffect(() => {
    getWallTypes(language).then((response: any) => {
      // console.log(response);
      setAllWallType(response);
    });
  }, [language]);

  useEffect(() => {
    getMoveIn(language).then((response: any) => {
      setAllMoveIn(response);
    });

    getParking(language).then((response: any) => {
      setAllParking(response);
    });

    getView(language).then((response: any) => {
      setAllView(response);
    });

    getFurnished(language).then((response: any) => {
      setAllFurnished(response);
    });

    getFloorLocated(language).then((response: any) => {
      setAllFloorLocated(response);
    });

    getElectricity(language).then((response: any) => {
      setAllElectricity(response);
    });

    getWater(language).then((response: any) => {
      setAllWater(response);
    });

    getGas(language).then((response: any) => {
      setAllGas(response);
    });

    getCanal(language).then((response: any) => {
      setAllCanal(response);
    });
  }, [language]);

  useEffect(() => {
    applyFilters();
  }, []);

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);

  const closeModalMoreFilter = () => {
    setisOpenMoreFilter(false);
    applyFilters();
    setIsFilteringActive(true);
    setFieldNameShow('nothing');
  };
  const closeModalMoreFilterOnClear = () => {
    setisOpenMoreFilter(false);
    resetFilters();
  };
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const applyFilterHandler = () => {
    applyFilters();
  };

  const applyFilters = () => {
    getAllProperties({
      sortby: '',
      page: '',
      ...PropertiesContextState.searchQuery,
      language: language,
      city: selectedCity,
      district: selectedDistrict,
      county: selectedCounty,
      type: selectedPropertyType,
      status: status,
      minprice: rangePrices[0] * priceMultiplier,
      maxprice: rangePrices[1] * priceMultiplier,
      minprice_m2: rangeSquarePrices[0],
      maxprice_m2: rangeSquarePrices[1],
      minarea: rangeArea[0],
      maxarea: rangeArea[1],
      min_balcony_area: rangeBalconyArea[0],
      max_balcony_area: rangeBalconyArea[1],
      min_terrace_area: rangeTerraceArea[0],
      max_terrace_area: rangeTerraceArea[1],
      min_plot_area: rangePlotArea[0],
      max_plot_area: rangePlotArea[1],
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      floors: floors,
      condition: selectedConditions,
      all_type: selectedPropertySubtypes,
      heating: selectedHeating,
      wall_type: selectedWallTypes,
      min_construction_year: rangeConstructionYear[0],
      max_construction_year: rangeConstructionYear[1],
      min_inner_height: rangeInnerHeight[0],
      max_inner_height: rangeInnerHeight[1],
      move_in: selectedMoveIn,
      parking: selectedParking,
      view: selectedView,
      furnished: selectedFurnished,
      floor_located: selectedFloorLocated,
      electricity: selectedElectricity,
      water: selectedWater,
      gas: selectedGas,
      canal: selectedCanal,
      extras: selectedExtras,
      bathroom_type: selectedBathroomTypes,
    }).then((response) => {
      console.log(response);
      PropertiesContextState.setContext({
        results: response,
        searchQuery: {
          sortby: '',
          page: '',
          ...PropertiesContextState.searchQuery,
          language: language,
          city: selectedCity,
          district: selectedDistrict,
          county: selectedCounty,
          type: selectedPropertyType,
          status: status,
          minprice: rangePrices[0] * priceMultiplier,
          maxprice: rangePrices[1] * priceMultiplier,
          minprice_m2: rangeSquarePrices[0],
          maxprice_m2: rangeSquarePrices[1],
          minarea: rangeArea[0],
          maxarea: rangeArea[1],
          min_balcony_area: rangeBalconyArea[0],
          max_balcony_area: rangeBalconyArea[1],
          min_terrace_area: rangeTerraceArea[0],
          max_terrace_area: rangeTerraceArea[1],
          min_plot_area: rangePlotArea[0],
          max_plot_area: rangePlotArea[1],
          bedrooms: bedrooms,
          bathrooms: bathrooms,
          floors: floors,
          condition: selectedConditions,
          all_type: selectedPropertySubtypes,
          heating: selectedHeating,
          wall_type: selectedWallTypes,
          min_construction_year: rangeConstructionYear[0],
          max_construction_year: rangeConstructionYear[1],
          min_inner_height: rangeInnerHeight[0],
          max_inner_height: rangeInnerHeight[1],
          move_in: selectedMoveIn,
          parking: selectedParking,
          view: selectedView,
          furnished: selectedFurnished,
          floor_located: selectedFloorLocated,
          electricity: selectedElectricity,
          water: selectedWater,
          gas: selectedGas,
          canal: selectedCanal,
          extras: selectedExtras,
          bathroom_type: selectedBathroomTypes,
        },
        activePropertyTypeId: PropertiesContextState.activePropertyTypeId,
      });
    });
    // setIsFilteringActive(true);
  };

  const resetLocationFilters = () => {
    setSelectedCityObj([]);

    allDistrict.forEach((element) => {
      delete element['checked'];
    });

    allCounty.forEach((element) => {
      delete element['checked'];
    });
  };

  const resetFilters = () => {
    // TODO both rent and status neccessary here?
    setIsRent(false);
    setStatus(defaultStatus);
    setSelectedCity([]);
    setSelectedDistrict([]);
    setSelectedCounty([]);
    setCityText('');
    setSelectedPropertyType(defaultSelectedPropertyType);
    setSelectedPropertyTypeText('');
    setAllPropertySubtype([]);
    setRangePrices(defaultRangePrices);
    setRangeSquarePrices(defaultRangeSquarePrice);
    setRangeBalconyArea(defaultRangeBalcony);
    setRangeTerraceArea(defaultRangeTerrace);
    setRangePlotArea(defaultRangePlot);
    setRangeArea([defaultMinArea, defaultMaxArea]);
    setBathrooms(defaultBathrooms);
    setBedrooms(defaultBedrooms);
    setFloors(defaultFloor);
    // @ts-ignore
    setSelectedConditions(defaultConditions);
    // @ts-ignore
    setSelectedExtras(defaultExtras);
    // @ts-ignore
    setSelectedBathroomtypes(defaultBathroomTypes);
    // @ts-ignore
    setSelectedPropertySubtypes(defaultSubCategories);
    // @ts-ignore
    setSelectedHeating(defaultHeating);
    // @ts-ignore
    setSelectedWallTypes(defaultWallTypes);
    setRangeConstructionYear(defaultRangeConstructionYear);
    // @ts-ignore
    setSelectedMoveIn(defaultMoveIn);
    // @ts-ignore
    setSelectedParking(defaultParking);
    // @ts-ignore
    setSelectedView(defaultView);
    // @ts-ignore
    setSelectedFurnished(defaultFurnished);
    // @ts-ignore
    setSelectedFloorLocated(defaultFloorLocated);
    // @ts-ignore
    setSelectedElectricity(defaultElectricity);
    // @ts-ignore
    setSelectedWater(defaultWater);
    // @ts-ignore
    setSelectedGas(defaultGas);
    // @ts-ignore
    setSelectedCanal(defaultCanal);

    setSelectedCityObj([]);

    condition.forEach((element) => {
      delete element['checked'];
    });

    allDistrict.forEach((element) => {
      delete element['checked'];
    });

    allCounty.forEach((element) => {
      delete element['checked'];
    });

    allPropertySubtype.forEach((element) => {
      delete element['checked'];
    });

    allHeating.forEach((element) => {
      delete element['checked'];
    });

    allWallType.forEach((element) => {
      delete element['checked'];
    });

    allMoveIn.forEach((element) => {
      delete element['checked'];
    });

    extras.forEach((element) => {
      delete element['checked'];
    });

    bathroomtypes.forEach((element) => {
      delete element['checked'];
    });

    allParking.forEach((element) => {
      delete element['checked'];
    });

    allView.forEach((element) => {
      delete element['checked'];
    });

    allFurnished.forEach((element) => {
      delete element['checked'];
    });

    allFloorLocated.forEach((element) => {
      delete element['checked'];
    });

    allElectricity.forEach((element) => {
      delete element['checked'];
    });

    allWater.forEach((element) => {
      delete element['checked'];
    });

    allGas.forEach((element) => {
      delete element['checked'];
    });

    allCanal.forEach((element) => {
      delete element['checked'];
    });

    setRangeInnerHeight(defaultRangeInnerHeight);

    getAllProperties({
      ...PropertiesContextState.searchQuery,
      page: 1,
      city: 0,
      district: 0,
      county: 0,
      type: defaultSelectedPropertyType,
      status: defaultStatus,
      minprice: defaultRangePrices[0] * defaultPriceMultiplier,
      maxprice: defaultRangePrices[1] * defaultPriceMultiplier,
      minprice_m2: defaultRangeSquarePrice[0],
      maxprice_m2: defaultRangeSquarePrice[1],
      min_balcony_area: defaultRangeBalcony[0],
      max_balcony_area: defaultRangeBalcony[1],
      min_terrace_area: defaultRangeTerrace[0],
      max_terrace_area: defaultRangeTerrace[1],
      min_plot_area: defaultRangePlot[0],
      max_plot_area: defaultRangePlot[1],
      minarea: defaultMinArea,
      maxarea: defaultMaxArea,
      bedrooms: defaultBedrooms,
      bathrooms: defaultBathrooms,
      floors: defaultFloor,
      // @ts-ignore
      condition: defaultConditions,
      // @ts-ignore
      all_type: defaultSubCategories,
      // @ts-ignore
      heating: defaultHeating,
      // @ts-ignore
      wall_type: defaultWallTypes,
      min_construction_year: defaultRangeConstructionYear[0],
      max_construction_year: defaultRangeConstructionYear[1],
      min_inner_height: defaultRangeInnerHeight[0],
      max_inner_height: defaultRangeInnerHeight[1],
      // @ts-ignore
      move_in: defaultMoveIn,
      // @ts-ignore
      parking: defaultParking,
      // @ts-ignore
      view: defaultView,
      // @ts-ignore
      furnished: defaultFurnished,
      // @ts-ignore
      floor_located: defaultFloorLocated,
      // @ts-ignore
      electricity: defaultElectricity,
      // @ts-ignore
      water: defaultWater,
      // @ts-ignore
      gas: defaultGas,
      // @ts-ignore
      canal: defaultCanal,
      // @ts-ignore
      extras: defaultExtras,
      // @ts-ignore
      bathroom_type: defaultBathroomTypes,
    }).then((response) => {
      console.log(response);
      PropertiesContextState.setContext({
        results: response,
        searchQuery: {
          ...PropertiesContextState.searchQuery,
          page: 1,
          city: 0,
          district: 0,
          county: 0,
          type: defaultSelectedPropertyType,
          status: defaultStatus,
          minprice: defaultRangePrices[0] * defaultPriceMultiplier,
          maxprice: defaultRangePrices[1] * defaultPriceMultiplier,
          minprice_m2: defaultRangeSquarePrice[0],
          maxprice_m2: defaultRangeSquarePrice[1],
          min_balcony_area: defaultRangeBalcony[0],
          max_balcony_area: defaultRangeBalcony[1],
          min_terrace_area: defaultRangeTerrace[0],
          max_terrace_area: defaultRangeTerrace[1],
          min_plot_area: defaultRangePlot[0],
          max_plot_area: defaultRangePlot[1],
          minarea: defaultMinArea,
          maxarea: defaultMaxArea,
          bedrooms: defaultBedrooms,
          bathrooms: defaultBathrooms,
          floors: defaultFloor,
          // @ts-ignore
          condition: defaultConditions,
          // @ts-ignore
          all_type: defaultSubCategories,
          // @ts-ignore
          heating: defaultHeating,
          // @ts-ignore
          wall_type: defaultWallTypes,
          min_construction_year: defaultRangeConstructionYear[0],
          max_construction_year: defaultRangeConstructionYear[1],
          min_inner_height: defaultRangeInnerHeight[0],
          max_inner_height: defaultRangeInnerHeight[1],
          // @ts-ignore
          move_in: defaultMoveIn,
          // @ts-ignore
          parking: defaultParking,
          // @ts-ignore
          view: defaultView,
          // @ts-ignore
          furnished: defaultFurnished,
          // @ts-ignore
          floor_located: defaultFloorLocated,
          // @ts-ignore
          electricity: defaultElectricity,
          // @ts-ignore
          water: defaultWater,
          // @ts-ignore
          gas: defaultGas,
          // @ts-ignore
          canal: defaultCanal,
          // @ts-ignore
          extras: defaultExtras,
          // @ts-ignore
          bathroom_type: defaultBathroomTypes,
        },
        activePropertyTypeId: PropertiesContextState.activePropertyTypeId,
      });
    });
    setIsFilteringActive(false);
  };

  const renderMobileForm = () => {
    return (
      <button
        onClick={() => {
          openModalMoreFilter();
        }}
        className='relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg'
      >
        {/* <MagnifyingGlassIcon className='flex-shrink-0 w-5 h-5' /> */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
          />
        </svg>

        <div className='ml-3 flex-1 text-left overflow-hidden'>
          <span className='block font-medium text-sm'>Keresés</span>
          <span className='block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 line-clamp-1'>
            {/* Anywhere • Any week • Add guests */}
            Balatoni Ingatlanok
          </span>
        </div>
        {/* {renderTabMoreFilter()} */}

        <span className='absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300'>
          <svg
            viewBox='0 0 16 16'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            className='block w-4 h-4'
            fill='currentColor'
          >
            <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
          </svg>
        </span>
      </button>
    );
  };

  const renderXClear = () => {
    return (
      <span className='w-6 h-6 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-4 w-4'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </span>
    );
  };

  const renderPropertyStatus = () => {
    return (
      <div className='pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8'>
        <div className='relative focus:outline-none focus-visible:ring-0 outline-none select-none'>
          <div
            className={`${!isRent ? 'text-black dark:text-white' : ''}  `}
            onClick={() => {
              setIsRent(false);
            }}
          >
            Vásárlás
          </div>
          {!isRent && (
            <span className='absolute inset-x-0 top-full border-b-2 border-black dark:border-white'></span>
          )}
        </div>
        <div className='relative focus:outline-none focus-visible:ring-0 outline-none select-none'>
          <div
            className={`${isRent ? 'text-black dark:text-white' : ''}  `}
            onClick={() => {
              setIsRent(true);
            }}
          >
            Bérlés
          </div>
          {isRent && (
            <span className='absolute inset-x-0 top-full border-b-2 border-black dark:border-white'></span>
          )}
        </div>
      </div>
    );
  };

  const renderInputLocation = () => {
    const isActive = fieldNameShow === 'location';
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('location')}
          >
            <span>Város</span>
            <span>{cityText || 'Bármely'}</span>
          </button>
        ) : (
          <LocationInputMobile
            headingText='Merre keresnél?'
            suggestions={cities}
            defaultCities={selectedCityObj}
            defaultCounties={allCounty}
            defaultDistricts={allDistrict}
            defaultValue={cityText}
            onChange={(text, id) => {
              console.log('On change locationinput mobile: ');
              console.log(text, id);
              setCityText(text);
              //setSelectedCity(id);
              setFieldNameShow('price');
            }}
            onChangeSelectedCity={(selectedCitiesArray: any) => {
              // console.log('==============>>>>');

              console.log('-=-=-=-=> OnChangeSelectedCity Mobile');
              console.log(selectedCitiesArray);
              setSelectedCityObj(selectedCitiesArray);
              const selectedCityID = selectedCitiesArray.map((item: any) => {
                return item.cityId;
              });
              //setSelectedCityIDs(selectedCityID);
              setSelectedCity(selectedCityID);
              // setAllDistrict(districtArray);
              // // @ts-ignore
              // setSelectedDistrict(selectedDistrictIDs);
              // setSelectedCity(IDs);
            }}
            onChangeSelectedDistrict={(
              districtArray: any,
              selectedDistrictIDs
            ) => {
              console.log('Form -> Location Input Mobile element');
              console.log(districtArray);
              console.log(selectedDistrictIDs);
              setAllDistrict(districtArray);
              // @ts-ignore
              setSelectedDistrict(selectedDistrictIDs);
            }}
            onChangeSelectedCounty={(countyArray: any, selectedCountyIDs) => {
              setAllCounty(countyArray);
              // @ts-ignore
              setSelectedCounty(selectedCountyIDs);
            }}
            resetLocationFilter={resetLocationFilters}
          />
        )}
      </div>
    );
  };

  const renderInputPropertyType = () => {
    const isActive = fieldNameShow === 'propertyType';

    let typeOfPropertyText = selectedPropertyTypeText
      ? selectedPropertyTypeText
      : 'Bármely';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('propertyType')}
          >
            <span>Ingatlan típusa</span>
            <span className='truncate ml-5'>
              {typeOfPropertyText || 'Add property'}
            </span>
          </button>
        ) : (
          <PropertyTypeSelectMobile
            // defaultValue={typeOfProperty}
            // onChange={setTypeOfProperty}
            types={propertyTypes}
            onChange={(selectedPropertyTitle: any, selectedPropertyID: any) => {
              setSelectedPropertyTypeText(selectedPropertyTitle);
              setSelectedPropertyType(selectedPropertyID);
              setFieldNameShow('location');
            }}
          />
        )}
      </div>
    );
  };

  const renderPriceRange = () => {
    const isActive = fieldNameShow === 'price';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('price')}
          >
            <span>Ár</span>
            <span>
              {`${convertNumbThousand(rangePrices[0])} ~ ${convertNumbThousand(
                rangePrices[1]
              )}`}
              {isRent ? ' /hó' : ' million HUF'}
            </span>
          </button>
        ) : (
          <PriceRangeInputMobile
            defaultValue={rangePrices}
            onChange={setRangePrices}
            rentMode={isRent}
          />
        )}
      </div>
    );
  };

  const renderPriceSquareRange = () => {
    const isActive = fieldNameShow === 'price_m2';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('price_m2')}
          >
            <span>Négyzetméter ár</span>
            <span>{`${rangeSquarePrices[0]} ~ ${rangeSquarePrices[1]} m²`}</span>
          </button>
        ) : (
          <AreaRangeInput
            defaultValue={rangeSquarePrices}
            sectionTitle={'Négyzetméter ár'}
            minMaxDescription={'négyzet méter ár'}
            onChange={setRangeSquarePrices}
            rangeMinValue={0}
            rangeMaxValue={1000000}
            unit={' Ft /m²'}
          />
        )}
      </div>
    );
  };

  const renderAreaRange = () => {
    const isActive = fieldNameShow === 'area';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('area')}
          >
            <span>Alapterület</span>
            <span>{`${rangeArea[0]} ~ ${rangeArea[1]} m²`}</span>
          </button>
        ) : (
          <AreaRangeInput
            defaultValue={rangeArea}
            sectionTitle={'Alapterület'}
            minMaxDescription={'alapterület'}
            onChange={setRangeArea}
            rangeMinValue={0}
            rangeMaxValue={300}
          />
        )}
      </div>
    );
  };

  const renderBalconyAreaRange = () => {
    const isActive = fieldNameShow === 'balconyarea';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('balconyarea')}
          >
            <span>Erkély területe</span>
            <span>{`${rangeBalconyArea[0]} ~ ${rangeBalconyArea[1]} m²`}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <AreaRangeInput
                defaultValue={rangeBalconyArea}
                sectionTitle={'Erkély területe'}
                minMaxDescription={'erkély terület'}
                onChange={setRangeBalconyArea}
                rangeMinValue={0}
                rangeMaxValue={100}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Erkély területe'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderTerraceAreaRange = () => {
    const isActive = fieldNameShow === 'terracearea';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('terracearea')}
          >
            <span>Terasz területe</span>
            <span>{`${rangeTerraceArea[0]} ~ ${rangeTerraceArea[1]} m²`}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <AreaRangeInput
                defaultValue={rangeTerraceArea}
                sectionTitle={'Terasz területe'}
                minMaxDescription={'terasz terület'}
                onChange={setRangeTerraceArea}
                rangeMinValue={0}
                rangeMaxValue={100}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Terasz területe'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderPlotAreaRange = () => {
    const isActive = fieldNameShow === 'plotarea';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('plotarea')}
          >
            <span>Telek területe</span>
            <span>{`${rangePlotArea[0]} ~ ${rangePlotArea[1]} m²`}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <AreaRangeInput
                defaultValue={rangePlotArea}
                sectionTitle={'Telek területe'}
                minMaxDescription={'telek terület'}
                onChange={setRangePlotArea}
                rangeMinValue={0}
                rangeMaxValue={10000}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Telek területe'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderConstructionYearRange = () => {
    const isActive = fieldNameShow === 'construction_year';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('construction_year')}
          >
            <span>Építés éve</span>
            {/* <span>{`${rangeConstructionYear[0]} ~ ${rangeConstructionYear[1]}`}</span> */}
            <span className='truncate ml-5'>
              {rangeConstructionYear[0] === 0
                ? `1940  ~ ${rangeConstructionYear[1]}`
                : `${rangeConstructionYear[0]} ~ ${rangeConstructionYear[1]}`}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <AreaRangeInput
                defaultValue={rangeConstructionYear}
                sectionTitle={'Építés éve'}
                minMaxDescription={'építés éve'}
                onChange={setRangeConstructionYear}
                rangeMinValue={1940}
                rangeMaxValue={2023}
                unit={''}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Építés éve'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderInnerHeightRange = () => {
    const isActive = fieldNameShow === 'inner_height';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('inner_height')}
          >
            <span>Belmagasság</span>
            {/* <span>{`${rangeConstructionYear[0]} ~ ${rangeConstructionYear[1]}`}</span> */}
            <span>{`${rangeInnerHeight[0]} ~ ${rangeInnerHeight[1]} cm`}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <AreaRangeInput
                defaultValue={rangeInnerHeight}
                sectionTitle={'Belmagasság'}
                minMaxDescription={'belmagasság (cm)'}
                onChange={setRangeInnerHeight}
                rangeMinValue={0}
                rangeMaxValue={500}
                unit={'cm'}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Belmagasság'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderConditionSelect = () => {
    const isActive = fieldNameShow === 'condition';

    let typeOfPropertyConditionText = '';
    if (condition && condition.length > 0) {
      typeOfPropertyConditionText = condition
        // @ts-ignore
        .filter((item) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('condition')}
          >
            <span>Ingatlan állapota</span>
            <span className='truncate ml-5'>
              {typeOfPropertyConditionText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={condition}
                sectionTitle={'Ingatlan Állapota'}
                onChange={(conditionArray, selectedConditionIDs) => {
                  // @ts-ignore
                  setCondition(conditionArray);
                  setSelectedConditions(selectedConditionIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Ingatlan állapota'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderBedroomsBathrooms = () => {
    const isActive = fieldNameShow === 'bedbath';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('bedbath')}
          >
            <span className='text-left'>Hálószobák, fürdőszobák</span>
            <span className='text-right'>{`Minimum ${bedrooms} szoba, ${bathrooms} hálószoba`}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  Szobák, fürdőszobák száma
                </span>

                <div className='relative flex flex-col space-y-5 mt-7'>
                  <NcInputNumber
                    label='Hálószobák'
                    defaultValue={bedrooms}
                    max={10}
                    onChange={(bedrooms) => {
                      setBedrooms(bedrooms);
                    }}
                    /* @ts-ignore */
                    // ref={setBedRef}
                  />
                  <NcInputNumber
                    label='Fürdőszobák'
                    defaultValue={bathrooms}
                    max={10}
                    onChange={(baths) => {
                      setBathrooms(baths);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Szobák, fürdőszobák száma'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderFloor = () => {
    const isActive = fieldNameShow === 'floor';

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('floor')}
          >
            <span>Szintek száma</span>
            <span>{`Minimum ${floors} szint`}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  Szintek száma
                </span>

                <div className='relative flex flex-col space-y-5 mt-7'>
                  <NcInputNumber
                    label='Szintek'
                    defaultValue={floors}
                    max={10}
                    onChange={(floors) => {
                      setFloors(floors);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Szintek száma'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderPropertySubtype = () => {
    const isActive = fieldNameShow === 'propertysub';

    let propertySubtypeText = '';
    //console.log(allPropertySubtype);
    if (
      selectedPropertyType &&
      allPropertySubtype &&
      allPropertySubtype.length !== 0
    ) {
      propertySubtypeText = allPropertySubtype
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('propertysub')}
          >
            <span>Ingatlan altípusa</span>
            <span className='truncate ml-5'>
              {propertySubtypeText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allPropertySubtype}
                sectionTitle={'Ingatlan altípusa'}
                onChange={(subtypeArray, selectedSubTypeIDs) => {
                  // @ts-ignore
                  setAllPropertySubtype(subtypeArray);
                  setSelectedPropertySubtypes(selectedSubTypeIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Ingatlan altípusa'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderHeatings = () => {
    const isActive = fieldNameShow === 'heatings';

    let propertyHeatingText = '';
    if (allHeating && allHeating.length !== 0) {
      propertyHeatingText = allHeating
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('heatings')}
          >
            <span>Fűtés típusa</span>
            <span className='truncate ml-5'>
              {propertyHeatingText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allHeating}
                sectionTitle={'Fűtés típusa'}
                onChange={(heatingArray: any, selectedIDs) => {
                  setAllHeating(heatingArray);
                  setSelectedHeating(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Fűtés típusa'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderWalls = () => {
    const isActive = fieldNameShow === 'walls';

    let wallText = '';
    if (allWallType && allWallType.length !== 0) {
      wallText = allWallType
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('walls')}
          >
            <span>Falazat típusa</span>
            <span className='truncate ml-5'>{wallText || 'Bármely'}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allWallType}
                sectionTitle={'Falazat típusa'}
                onChange={(wallArray: any, selectedIDs) => {
                  setAllWallType(wallArray);
                  setSelectedWallTypes(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Falazat típusa'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderMoveIn = () => {
    const isActive = fieldNameShow === 'move_in';

    let propertyMoveInText = '';
    if (allMoveIn && allMoveIn.length !== 0) {
      propertyMoveInText = allMoveIn
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }
    // if (allMoveIn && allMoveIn.length !== 0) {
    //   propertyMoveInText = allMoveIn
    //     .filter((item: any) => selectedMoveIn.includes(item.id))
    //     .map((item: any) => {
    //       return item.title;
    //     })
    //     .join(', ');
    // }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('move_in')}
          >
            <span>Beköltözhető</span>
            <span className='truncate ml-5'>
              {propertyMoveInText || 'Bármikor'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allMoveIn}
                sectionTitle={'Beköltözhető'}
                onChange={(moveInArray: any, selectedIDs) => {
                  setAllMoveIn(moveInArray);
                  setSelectedMoveIn(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Beköltözhető'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderParking = () => {
    const isActive = fieldNameShow === 'parking';

    let propertyParkingText = '';
    if (allParking && allParking.length !== 0) {
      propertyParkingText = allParking
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('parking')}
          >
            <span>Parkolás</span>
            <span className='truncate ml-5'>
              {propertyParkingText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allParking}
                sectionTitle={'Parkolás'}
                onChange={(parkingArray: any, selectedIDs) => {
                  setAllParking(parkingArray);
                  setSelectedParking(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Parkolás'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderView = () => {
    const isActive = fieldNameShow === 'view';

    let propertyViewText = '';
    if (allView && allView.length !== 0) {
      propertyViewText = allView
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('view')}
          >
            <span>Kilátás</span>
            <span className='truncate ml-5'>
              {propertyViewText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allView}
                sectionTitle={'Kilátás'}
                onChange={(viewArray: any, selectedIDs) => {
                  setAllView(viewArray);
                  setSelectedView(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Kilátás'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderFurnished = () => {
    const isActive = fieldNameShow === 'furnished';

    let propertyFurnishedText = '';
    if (allFurnished && allFurnished.length !== 0) {
      propertyFurnishedText = allFurnished
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('furnished')}
          >
            <span>Bútorozott</span>
            <span className='truncate ml-5'>
              {propertyFurnishedText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allFurnished}
                sectionTitle={'Bútorozott'}
                onChange={(furnishedArray: any, selectedIDs) => {
                  setAllFurnished(furnishedArray);
                  setSelectedFurnished(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Bútorozott'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderFloorLocated = () => {
    const isActive = fieldNameShow === 'floor_located';

    let PropertyFloorLocatedText = '';
    if (allFloorLocated && allFloorLocated.length !== 0) {
      PropertyFloorLocatedText = allFloorLocated
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('floor_located')}
          >
            <span>Ezen a szinten található</span>
            <span className='truncate ml-5'>
              {PropertyFloorLocatedText || 'Bármely'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allFloorLocated}
                sectionTitle={'Ezen a szinten található'}
                onChange={(floorLocatedArray: any, selectedIDs) => {
                  setAllFloorLocated(floorLocatedArray);
                  setSelectedFloorLocated(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Ezen a szinten található'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderElectricity = () => {
    const isActive = fieldNameShow === 'electricity';

    let PropertyElectricityText = '';
    if (allElectricity && allElectricity.length !== 0) {
      PropertyElectricityText = allElectricity
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('electricity')}
          >
            <span>Elektromos áram</span>
            <span className='truncate ml-5'>
              {PropertyElectricityText || 'Mindegy'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allElectricity}
                sectionTitle={'Elektromos áram'}
                onChange={(electricityArray: any, selectedIDs) => {
                  setAllElectricity(electricityArray);
                  setSelectedElectricity(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Elektromos áram'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderWater = () => {
    const isActive = fieldNameShow === 'water';

    let PropertyWaterText = '';
    if (allWater && allWater.length !== 0) {
      PropertyWaterText = allWater
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('water')}
          >
            <span>Víz</span>
            <span className='truncate ml-5'>
              {PropertyWaterText || 'Mindegy'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allWater}
                sectionTitle={'Víz'}
                onChange={(waterArray: any, selectedIDs) => {
                  setAllWater(waterArray);
                  setSelectedWater(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Víz'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderGas = () => {
    const isActive = fieldNameShow === 'gas';

    let PropertyGasText = '';
    if (allGas && allGas.length !== 0) {
      PropertyGasText = allGas
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('gas')}
          >
            <span>Gáz</span>
            <span className='truncate ml-5'>
              {PropertyGasText || 'Mindegy'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allGas}
                sectionTitle={'Gáz'}
                onChange={(gasArray: any, selectedIDs) => {
                  setAllGas(gasArray);
                  setSelectedGas(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Gáz'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderCanal = () => {
    const isActive = fieldNameShow === 'canal';

    let PropertyCanalText = '';
    if (allCanal && allCanal.length !== 0) {
      PropertyCanalText = allCanal
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('canal')}
          >
            <span>Csatorna</span>
            <span className='truncate ml-5'>
              {PropertyCanalText || 'Mindegy'}
            </span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                defaultValue={allCanal}
                sectionTitle={'Csatorna'}
                onChange={(canalArray: any, selectedIDs) => {
                  setAllCanal(canalArray);
                  setSelectedCanal(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Csatorna'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderExtras = () => {
    const isActive = fieldNameShow === 'extras';

    let extraText = '';
    if (extras && extras.length !== 0) {
      extraText = extras
        // @ts-ignore
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('extras')}
          >
            <span>Extrák</span>
            <span className='truncate ml-5'>{extraText || 'Semelyik'}</span>
          </button>
        ) : (
          <>
            {allPropertySubtype.length !== 0 ? (
              <MultipleSelect
                // @ts-ignore
                defaultValue={extras}
                sectionTitle={'Extrák'}
                onChange={(extraArray: any, selectedIDs) => {
                  setExtras(extraArray);
                  setSelectedExtras(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Extrák'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderBathroomTypes = () => {
    const isActive = fieldNameShow === 'bathtype';

    let bathText = '';
    if (bathroomtypes && bathroomtypes.length !== 0) {
      bathText = bathroomtypes
        // @ts-ignore
        .filter((item) => item.checked)
        .map((item: any) => {
          return item.title;
        })
        .join(', ');
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? 'rounded-2xl shadow-lg'
            : 'rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]'
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow('bathtype')}
          >
            <span>Fürdőszoba felszereltsége</span>
            <span className='truncate ml-5'>{bathText || 'Bármely'}</span>
          </button>
        ) : (
          <>
            {selectedPropertyType !== 0 ? (
              <MultipleSelect
                // @ts-ignore
                defaultValue={bathroomtypes}
                sectionTitle={'Fürdőszoba felszereltsége'}
                onChange={(bathArray: any, selectedIDs) => {
                  setBathroomTypes(bathArray);
                  setSelectedBathroomtypes(selectedIDs);
                }}
              />
            ) : (
              <div className='p-5'>
                <span className='block font-semibold text-xl sm:text-2xl'>
                  {'Fürdőszoba felszereltsége'}
                </span>
                {firstChoosePropertyType()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderTabMoreFilter = () => {
    return (
      // @ts-ignore
      <div>
        {isMobile ? (
          <>{renderMobileForm()}</>
        ) : (
          <div className='hidden sm:flex relative'>
            <div
              className={`flex relative flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left [ nc-hero-field-padding ] hover:shadow-2xl rounded-full ${
                !!isFilteringActive ? '' : ''
              }`}
            >
              {/* <div
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border ${
                !!isFilteringActive ? 'border-primary-500 bg-primary-50' : ''
              } text-primary-700 focus:outline-none cursor-pointer`}
            > */}
              <div className='text-neutral-300 dark:text-neutral-400'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                    d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                  ></path>
                </svg>
              </div>
              <div
                className='flex-1'
                onClick={() => {
                  openModalMoreFilter();
                }}
              >
                <span className='block xl:text-lg font-semibold overflow-hidden'>
                  <span className='line-clamp-1'>{`Részletes`}</span>
                </span>
                <span className='block mt-1 text-sm text-neutral-400 leading-none font-light '>
                  Részletes keresés
                </span>
              </div>
              {isFilteringActive && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    resetFilters();
                  }}
                >
                  {renderXClear()}
                </span>
              )}
            </div>
          </div>
        )}

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 z-50 overflow-y-auto'
            onClose={closeModalMoreFilter}
          >
            <div className='min-h-screen text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60' />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className='inline-block h-screen align-middle'
                aria-hidden='true'
              >
                &#8203;
              </span>
              <Transition.Child
                className='inline-block py-8 h-screen w-full'
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      További szűrés
                    </Dialog.Title>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div
                    className='flex-grow overflow-y-auto bg-neutral-100'
                    ref={popupRef}
                  >
                    {/* px-10 */}
                    <div className='px-1 sm:px-4 pb-8 space-y-5 divide-neutral-200 dark:divide-neutral-800'>
                      {/* ---- */}
                      {renderPropertyStatus()}
                      {renderInputPropertyType()}
                      {renderInputLocation()}
                      {renderPriceRange()}
                      {renderPriceSquareRange()}
                      {renderAreaRange()}
                      {showBalconyAreaAtID.includes(selectedPropertyType) &&
                        renderBalconyAreaRange()}
                      {showTerraceAreaAtID.includes(selectedPropertyType) &&
                        renderTerraceAreaRange()}
                      {showPlotAreaAtID.includes(selectedPropertyType) &&
                        renderPlotAreaRange()}
                      {showConditionAtID.includes(selectedPropertyType) &&
                        renderConditionSelect()}
                      {renderPropertySubtype()}
                      {showBedBathsAtID.includes(selectedPropertyType) &&
                        renderBedroomsBathrooms()}
                      {showFloorAtID.includes(selectedPropertyType) &&
                        renderFloor()}
                      {showFloorLocatedAtID.includes(selectedPropertyType) &&
                        renderFloorLocated()}
                      {showHeatingAtID.includes(selectedPropertyType) &&
                        renderHeatings()}
                      {showWallTypeAtID.includes(selectedPropertyType) &&
                        renderWalls()}
                      {showInnerHeightAtID.includes(selectedPropertyType) &&
                        renderInnerHeightRange()}
                      {showBuiltYearAtID.includes(selectedPropertyType) &&
                        renderConstructionYearRange()}
                      {showViewAtID.includes(selectedPropertyType) &&
                        renderView()}
                      {showMoveInAtID.includes(selectedPropertyType) &&
                        renderMoveIn()}
                      {showFurnishedAtID.includes(selectedPropertyType) &&
                        renderFurnished()}
                      {renderParking()}
                      {showElectricityAtID.includes(selectedPropertyType) &&
                        renderElectricity()}
                      {showWaterAtID.includes(selectedPropertyType) &&
                        renderWater()}
                      {showGasAtID.includes(selectedPropertyType) &&
                        renderGas()}
                      {showCanalAtID.includes(selectedPropertyType) &&
                        renderCanal()}
                      {showExtrasAtID.includes(selectedPropertyType) &&
                        renderExtras()}
                      {showBathroomTypesAtID.includes(selectedPropertyType) &&
                        renderBathroomTypes()}
                    </div>
                  </div>

                  <div className='p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
                    <ButtonThird
                      onClick={() => {
                        closeModalMoreFilterOnClear();
                      }}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Szűrők Törlése
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Szűrés
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <>
        <form className='w-full hidden sm:inline-flex relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0'>
          <LocationInput
            defaultValue={cityText}
            defaultCities={selectedCityObj}
            defaultCounties={allCounty}
            defaultDistricts={allDistrict}
            onChangeSelectedCity={(selectedCitiesArray: any) => {
              console.log('-=-=-=-=> OnChangeSelectedCity Desktop');
              console.log(selectedCitiesArray);
              setSelectedCityObj(selectedCitiesArray);
              const selectedCityID = selectedCitiesArray.map((item: any) => {
                return item.cityId;
              });
              setSelectedCity(selectedCityID);
            }}
            onChangeSelectedDistrict={(
              districtArray: any,
              selectedDistrictIDs
            ) => {
              console.log('Form -> Location Input Desktop element');
              console.log(districtArray);
              console.log(selectedDistrictIDs);
              setAllDistrict(districtArray);
              // @ts-ignore
              setSelectedDistrict(selectedDistrictIDs);
            }}
            onChangeSelectedCounty={(countyArray: any, selectedCountyIDs) => {
              setAllCounty(countyArray);
              // @ts-ignore
              setSelectedCounty(selectedCountyIDs);
            }}
            suggestions={cities}
          />
          {propertyTypes && (
            <PropertyTypeSelect
              defaultValue={selectedPropertyTypeText}
              types={propertyTypes}
              onChange={(
                selectedPropertyTitle: any,
                selectedPropertyID: any
              ) => {
                setSelectedPropertyTypeText(selectedPropertyTitle);
                setSelectedPropertyType(selectedPropertyID);
                // PropertiesContextState.setContext({
                //   results: PropertiesContextState.results,
                //   searchQuery: PropertiesContextState.searchQuery,
                //   activePropertyTypeId: selectedPropertyID,
                // });
              }}
            />
          )}

          <PriceRangeInput
            defaultValue={rangePrices}
            //@ts-ignore
            rentMode={status === 2}
            onChangeParent={(priceArray: any) => {
              console.log('Onchange parent prices: ');
              console.log(priceArray);
              setRangePrices(priceArray);
            }}
          />

          {renderTabMoreFilter()}

          {/* BUTTON SUBMIT OF FORM */}

          {/* TODO: not a link but a datacontroller call */}
          <div className='px-4 py-4 lg:py-0'>
            {/* <ButtonSubmit /> */}
            {/* <ButtonCircle
            onClick={() => {
              console.log('Clicked');
            }}
          /> */}
            {/* <BtnLikeIcon
            onPress={() => {
              console.log('Pressed');
            }}
          />
          <ButtonPrimary />
          <ButtonThird /> */}

            <ButtonSearch
              onPress={() => {
                applyFilters();
              }}
            />
          </div>
        </form>
      </>
    );
  };

  const renderTab = () => {
    return (
      <ul className='hidden sm:inline-flex ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar'>
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                // @ts-ignore
                // setStatus(getIdForPropertyTypeSearch(tab, tabs) + 1);
                const statusWthCorrection =
                  getIdForPropertyTypeSearch(tab, tabs) !== 'any'
                    ? // @ts-ignore
                      parseInt(getIdForPropertyTypeSearch(tab, tabs)) + 1
                    : 1;

                setStatus(statusWthCorrection);
                setIsRent(statusWthCorrection === 2 ? true : false);
              }}
              className={`flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ''
                  : 'text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-100'
              } `}
              key={tab}
            >
              {active && (
                <span className='block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2' />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={`nc-HeroRealEstateSearchForm w-full max-w-6xl pt-12 pb-8 lg:py-0 ${className}`}
      data-nc-id='HeroRealEstateSearchForm'
    >
      {mobileMinWidthBreakpoint <= windowSize.width ? (
        <>
          {renderTab()}
          {renderForm()}
        </>
      ) : (
        <>{renderTabMoreFilter()}</>
      )}
    </div>
  );
};

export default RealEstateSearchForm;
