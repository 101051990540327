// @ts-nocheck
import React, { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import LocationMarker from 'components/AnyReactComponent/LocationMarker';
import CommentListing from 'components/CommentListing/CommentListing';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import GuestsInput from 'components/HeroSearchForm/GuestsInput';
// import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
// import { DateRage } from 'components/HeroSearchForm/StaySearchForm';
import StartRating from 'components/StartRating/StartRating';
// import GoogleMapReact from 'google-map-react';
import useWindowSize from 'hooks/useWindowResize';
import moment from 'moment';
// import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
import LikeSaveBtns from './LikeSaveBtns';
import ShareBtn from './ShareBtn';
import ModalPhotos from './ModalPhotos';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { useParams } from 'react-router-dom';
import {
  getPropertyById,
  getGalleryByProperty,
  getExtrasByProperty,
  getBathroomByProperty,
  getPriceInEUR,
  getRelatedProperties,
} from 'data/DataController';
import ButtonSubmit from 'components/HeroSearchForm/ButtonSubmit';
import ButtonThird from 'shared/Button/ButtonThird';
import appConfig from 'config/appConfig';
import PropertyCardH from 'components/PropertyCardH/PropertyCardH';
import { Link } from 'react-router-dom';
import Button from 'shared/Button/Button';
import Label from 'components/Label/Label';
import Textarea from 'shared/Textarea/Textarea';
import ContactForm from 'components/ContactForm/ContactForm';
import StayCard from 'components/StayCard/StayCard';
import SectionRelatedSlider from 'components/SectionRelatedSlider/SectionRelatedSlider';
import PropertyTypeSelect from 'components/HeroSearchForm/PropertyTypeSelect';
import Page404 from 'containers/Page404/Page404';

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const Amenities_demos = [
  { name: 'la-key', icon: 'la-key' },
  { name: 'la-luggage-cart', icon: 'la-luggage-cart' },
  { name: 'la-shower', icon: 'la-shower' },
  { name: 'la-smoking', icon: 'la-smoking' },

  { name: 'la-spa', icon: 'la-spa' },
  { name: 'la-suitcase', icon: 'la-suitcase' },
  { name: 'la-suitcase-rolling', icon: 'la-suitcase-rolling' },
  { name: 'la-swimmer', icon: 'la-swimmer' },
  { name: 'la-swimming-pool', icon: 'la-swimming-pool' },
  { name: 'la-tv', icon: 'la-tv' },
  { name: 'la-umbrella-beach', icon: 'la-umbrella-beach' },
  { name: 'la-utensils', icon: 'la-utensils' },
  { name: 'la-wheelchair', icon: 'la-wheelchair' },
  { name: 'la-wifi', icon: 'la-wifi' },
  { name: 'la-baby-carriage', icon: 'la-baby-carriage' },
  { name: 'la-bath', icon: 'la-bath' },
  { name: 'la-bed', icon: 'la-bed' },
  { name: 'la-briefcase', icon: 'la-briefcase' },
  { name: 'la-car', icon: 'la-car' },
  { name: 'la-cocktail', icon: 'la-cocktail' },
  { name: 'la-coffee', icon: 'la-coffee' },
  { name: 'la-concierge-bell', icon: 'la-concierge-bell' },
  { name: 'la-dice', icon: 'la-dice' },
  { name: 'la-dumbbell', icon: 'la-dumbbell' },
  { name: 'la-hot-tub', icon: 'la-hot-tub' },
  { name: 'la-infinity', icon: 'la-infinity' },
];

const extra_icons = [
  { id: 1, icon: 'la-fan' },
  { id: 2, icon: 'la-luggage-cart' },
  { id: 3, icon: 'la-parking' },
  { id: 4, icon: 'la-cocktail' },
];

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = '',
  isPreviewMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const [property, setProperty] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [relatedProperties, setRelatedProperties] = useState([]);
  const [propertyExtras, setPropertyExtras] = useState([]);
  const [propertyBathroom, setPropertyBathroom] = useState([]);
  const [priceInEur, setPriceInEur] = useState();

  const featuredImage = property.image;

  let images = featuredImage;

  if (propertyImages.length >= 0) {
    const propertyImagesURLStrings = propertyImages.map(function (item: any) {
      return item['url'];
    });

    images = [featuredImage, ...propertyImagesURLStrings];
  }

  const [isOpenModalContact, setIsOpenModalContact] = useState(false);
  const [isOpenModalEmailSent, setIsOpenModalEmailSent] = useState(false);

  const windowSize = useWindowSize();

  // @ts-ignore
  let { id } = useParams();
  // console.log('Property id: ' + id);

  // TODO: get lang from cookie or context or set it to internal window storage at first load?
  // TODO: language in the dependency array
  useEffect(() => {
    getPropertyById('hu', id).then((response) => {
      try {
        // console.log('RESP');
        // console.log(response[0]);
        setProperty(response[0]);
      } catch {
        setProperty([false]);
      }
    });

    getGalleryByProperty('hu', id).then((response) => {
      // console.log('RESP Get gallery');
      // console.log(response);
      setPropertyImages(response);
    });

    getExtrasByProperty('hu', id).then((response) => {
      // console.log('RESP Get extras');
      // console.log(response);
      setPropertyExtras(response);
    });

    getBathroomByProperty('hu', id).then((response) => {
      // console.log('RESP Get bathroom');
      // console.log(response);
      setPropertyBathroom(response);
    });
  }, []);

  useEffect(() => {
    if (property.price) {
      getPriceInEUR(property.price).then((response) => {
        // console.log('Price response');
        // console.log(response);
        setPriceInEur(response);
      });
    }

    if (property.price) {
      getRelatedProperties(
        'hu',
        property.latitude,
        property.longitude,
        property.price,
        property.typeId,
        property.id
      ).then((response) => {
        // console.log('-------Related response');
        // console.log(response);
        setRelatedProperties(response);
      });
    }
  }, [property]);

  let mergedList;
  useEffect(() => {
    const mergeHelper = new Map(extra_icons.map((x) => [x.id, x]));

    for (const x of propertyExtras) {
      if (mergeHelper.has(x.id)) {
        const item = mergeHelper.get(x.id);
        mergeHelper.set(x.id, { ...item, ...x });
      } else {
        mergeHelper.set(x.id, x);
      }
    }
    const mergedListArray = [...mergeHelper.values()];
    // For sorted array
    // const mergedSortedList = [...mergeHelper.values()].sort((a, b) => a.id - b.id);
    // console.log(mergedListArray);
    mergedList = mergedListArray;
  }, [propertyExtras]);

  function closeModalContact() {
    setIsOpenModalContact(false);
  }

  function closeModalEmailSent() {
    setIsOpenModalEmailSent(false);
  }

  function openModalAmenities() {
    setIsOpenModalContact(true);
  }

  function openModalEmailSent() {
    setIsOpenModalEmailSent(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const onEmailSentHandler = () => {
    closeModalContact();
    openModalEmailSent();
  };

  const containsNumber = /\d/;

  const renderSectionMainCard = () => {
    return (
      <div className='listingSection__wrap !space-y-6'>
        {/* 1 */}
        <div className='flex flex-wrap justify-start items-center'>
          {/* <Badge name={property.type} /> */}
          {property.status && (
            <Badge
              className='mr-4 mt-4'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-tag'></i>
                  <span className='ml-1'>{property.status}</span>
                </div>
              }
              color={'green'}
            />
          )}
          {property.conditions && (
            <Badge
              className='mr-4 mt-4'
              // className='justify-self-start'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{property.conditions}</span>
                </div>
              }
              // color='yellow'
            />
          )}
          {property.type && !property.all_type && (
            <Badge
              className='mr-4 mt-4'
              // className='justify-self-start'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{property.type}</span>
                </div>
              }
              color='red'
            />
          )}
          {property.type && property.all_type && (
            <Badge
              className='mr-4 mt-4'
              // className='justify-self-start'
              name={
                <div className='flex items-center'>
                  <i className=' text-sm las la-home'></i>
                  <span className='ml-1'>{property.all_type}</span>
                </div>
              }
              color='red'
            />
          )}

          {/* <LikeSaveBtns /> */}
          <div className='flex ml-auto items-center'>
            <ShareBtn />
          </div>
        </div>

        {/* 2 */}
        <h2 className='text-2xl sm:text-3xl lg:text-4xl font-semibold'>
          {/* @ts-ignore */}
          {property.title}
        </h2>

        {/* 3 */}
        <div className='flex items-center space-x-4'>
          {/* <StartRating />
          <span>·</span> */}
          <span>
            <i className='las la-map-marker-alt'></i>
            <span className='ml-1'> {property.city}</span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className='flex flex-col items-center'>
          <Avatar sizeClass='h-36 w-36' radius='rounded-full' />
          <span className='ml-2.5 text-neutral-500 dark:text-neutral-400 mt-6'>
            Hosted by
            <span className='text-neutral-900 dark:text-neutral-200 font-medium'>
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

        {/* 6 */}
        <div className='flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300'>
          {property.size && (
            <div className='flex items-center space-x-3 '>
              <i className='las la-expand-arrows-alt text-2xl'></i>
              <span className=''>
                {property.size} <span className='hidden'></span>
              </span>
            </div>
          )}
          {property.beds && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-bed text-2xl'></i>
              <span className=' '>
                {property.beds + ' '}
                {property.half_beds != 0 && ' + ' + property.half_beds + ' '}
                <span className='hidden sm:inline-block'>hálószoba</span>
              </span>
            </div>
          )}
          {property.baths && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-bath text-2xl'></i>
              <span className=''>
                {property.baths + ' '}
                <span className='hidden sm:inline-block'>fürdőszoba</span>
              </span>
            </div>
          )}
          {property.garages != 0 && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-warehouse text-2xl'></i>
              <span className=' '>
                {property.garages + ' '}
                <span className='hidden sm:inline-block'>garázs</span>
              </span>
            </div>
          )}
          {property.floor != 0 && (
            <div className='flex items-center space-x-1 sm:space-x-3'>
              <i className='las la-caret-square-up text-2xl'></i>
              <span className=' '>
                {property.floor + ' '}
                <span className='hidden sm:inline-block'>szintes</span>
              </span>
            </div>
          )}
          {property.floor_located != 0 && (
            <div className='flex items-center space-x-3'>
              <i className='las la-caret-square-up text-2xl'></i>
              <span className=' '>
                {property.floor_located + '. '}
                <span className='hidden sm:inline-block'>emelet</span>
              </span>
            </div>
          )}
        </div>

        <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

        <div className='flow-root'>
          <div className='text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4'>
            {/* <div className='p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg'>
              <span>Éves áramfogyasztás kWh-ban</span>
              <span>1234 kWh</span>
            </div> */}
            {property.conditions && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Ingatlan állapota</span>
                <span>{property.conditions}</span>
              </div>
            )}
            {property.all_type && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Ingatlan kategóriája</span>
                <span className='text-right'>{property.all_type}</span>
              </div>
            )}
            {/* PLOT */}
            {property.electricity != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Elektromos áram</span>
                <span>{property.electricity}</span>
              </div>
            )}
            {property.water != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Víz</span>
                <span>{property.water}</span>
              </div>
            )}
            {property.gas != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Földgáz</span>
                <span>{property.gas}</span>
              </div>
            )}
            {property.canal != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Csatorna</span>
                <span>{property.canal}</span>
              </div>
            )}
            {property.price_m2 != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Négyzetméter ár</span>
                <span>{property.price_m2 + ' /m²'}</span>
              </div>
            )}
            {containsNumber.test(property.size) &&
              property.size !== '0 m²' &&
              property.size != 0 && (
                <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                  <span>Ingatlan alapterülete</span>
                  <span>{property.size}</span>
                </div>
              )}
            {property.plot_size !== 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Telek területe</span>
                <span>{property.plot_size}</span>
              </div>
            )}
            {property.balcony_size != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Erkély területe</span>{' '}
                <span>{property.balcony_size}</span>
              </div>
            )}
            {property.terrace_size != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Terasz területe</span>{' '}
                <span>{property.terrace_size}</span>
              </div>
            )}
            {property.inner_height != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Belmagasság</span>
                <span>{property.inner_height + ' cm'}</span>
              </div>
            )}
            {property.beds != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Hálószobák száma</span>
                <span>{property.beds}</span>
              </div>
            )}
            {property.half_beds != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Félszobák száma</span>
                <span>{property.half_beds}</span>
              </div>
            )}
            {property.baths && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Fürdőszobák száma</span>
                <span>{property.baths}</span>
              </div>
            )}
            {property.floor && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Emeletek száma</span>
                <span>{property.floor}</span>
              </div>
            )}
            {property.floor_located && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Ezen a szinten található</span>
                <span>{property.floor_located}</span>
              </div>
            )}
            {property.view && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Kilátás</span> <span>{property.view}</span>
              </div>
            )}
            {property.heating && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Fűtés típusa</span> <span>{property.heating}</span>
              </div>
            )}
            {property.heating_secondary && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Fűtés másodlagos típusa</span>
                <span>{property.heating_secondary}</span>
              </div>
            )}
            {property.furnished && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Bútorozott</span>
                <span>{property.furnished}</span>
              </div>
            )}
            {property.wall && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Falazat típusa</span>
                <span>{property.wall}</span>
              </div>
            )}
            {property.parking && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Parkolás</span> <span>{property.parking}</span>
              </div>
            )}
            {property.garages != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Garázsok száma</span> <span>{property.garages}</span>
              </div>
            )}
            {property.move_in && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Beköltözhető</span> <span>{property.move_in}</span>
              </div>
            )}
            {property.construction_year != 0 && (
              <div className='p-4 even:bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Építés éve</span>
                <span>{property.construction_year}</span>
              </div>
            )}
            {propertyBathroom.length !== 0 && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <div className='listingSection__wrap'>
                  <div>
                    {/* <h2 className='text-2xl font-semibold'>
                    Fürdőszoba felszereltsége
                  </h2> */}
                    <span className='block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold'>
                      Fürdőszoba felszereltsége
                    </span>
                  </div>
                  <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                  <div className='grid grid-cols-2 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 '>
                    {propertyBathroom
                      .filter((_, i) => i < 30)
                      .map((item) => (
                        <div
                          key={item.title}
                          className='flex items-center space-x-3'
                        >
                          <i className='las la-check-circle text-2xl'></i>
                          <span>{item.title}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {propertyExtras.length !== 0 && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <div className='listingSection__wrap'>
                  <div>
                    {/* <h2 className='text-2xl font-semibold'>Extrák</h2> */}
                    <span className='block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold'>
                      További, az ingatlanhoz járó felszereltség
                    </span>
                  </div>
                  <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
                  <div className='grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 '>
                    {propertyExtras
                      .filter((_, i) => i < 30)
                      .map((item) => (
                        <div
                          key={item.title}
                          className='flex items-center space-x-3'
                        >
                          <i className='las la-check-circle text-2xl'></i>
                          <span>{item.title}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionDescription = () => {
    return (
      <>
        {property.description && (
          <div className='listingSection__wrap'>
            <h2 className='text-2xl font-semibold'>Ingatlan leírása</h2>
            <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
            <div className='text-neutral-6000 dark:text-neutral-300'>
              <span style={{ whiteSpace: 'pre-line' }}>
                {property.description}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  // const renderSectionExtras = () => {
  //   return (
  //     <>
  //       {propertyExtras.length !== 0 && (
  //         <div className='listingSection__wrap'>
  //           <div>
  //             <h2 className='text-2xl font-semibold'>Extrák</h2>
  //             <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
  //               Az ingatlannal járó extrák
  //             </span>
  //           </div>
  //           <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
  //           <div className='grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 '>
  //             {propertyExtras
  //               .filter((_, i) => i < 30)
  //               .map((item) => (
  //                 <div key={item.title} className='flex items-center space-x-3'>
  //                   <i className='las la-check-circle text-2xl'></i>
  //                   <span>{item.title}</span>
  //                 </div>
  //               ))}
  //           </div>
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  const renderContact = () => {
    return (
      <Transition appear show={isOpenModalContact} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalContact}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full'>
                <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      Kapcsolatfelvétel
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalContact} />
                    </span>
                  </div>
                  <div className='overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <div className='p-0'>
                      {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'> */}
                      <div className=' w-full flex flex-col rounded-2xl dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 p-2 sm:p-4 xl:p-8'>
                        <div className='flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 '>
                          <div className=''>
                            <div className='text-center text-xl font-medium mb-12 flex flex-col justify-evenly h-full'>
                              <div className='mt-0 sm:-mt-20'>
                                <Avatar
                                  sizeClass='h-64 w-64 self-center'
                                  radius='rounded-2xl'
                                  // imgUrl='../../src/images/avatars/david_kep.jpg'
                                />
                                <div className='text-m font-semibold mt-2'>
                                  Kiss Péter
                                </div>
                                <span className='font-normal'>
                                  Ingatlan referens
                                </span>
                              </div>

                              <div className='mt-12 sm:-mt-28'>
                                <div className=''>
                                  <div className=''>
                                    <span className=''>
                                      {'Hívjon bizalommal'}
                                    </span>
                                  </div>
                                  <div className='mt-4'>
                                    <span className='sm:bg-[#0891B2] sm:text-neutral-50 sm:rounded-full sm:m-4 sm:p-3 sm:text-base'>
                                      {'+36 123 4567'}
                                    </span>
                                  </div>
                                  <a
                                    href='tel:+36305157795'
                                    className='block sm:hidden'
                                  >
                                    <ButtonPrimary
                                      type='submit'
                                      className='mt-2'
                                    >
                                      <i className='text-xl las la-phone-alt'></i>
                                      <span className='ml-2'>
                                        {'Érintse meg a híváshoz'}
                                      </span>
                                    </ButtonPrimary>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=''>
                            <div className='text-center text-xl font-medium mb-12'>
                              Vagy küldjön üzenetet
                            </div>
                            <ContactForm
                              className='p-4 sm:p-0'
                              propertyId={property.id}
                              reference={property.reference}
                              onEmailSent={onEmailSentHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  {
    /* {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className='flex items-center py-6 space-x-8'
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))} */
    /* SUBMIT */
    /* <a className='' href={`${appConfig.URL}contact-us-3`} target='_blank'>
            <ButtonPrimary>Kapcsolat</ButtonPrimary>
          </a> */
    /* <Link
            to={`${appConfig.URL}contact-us-3`}
            className='nc-Button text-neutral-50 relative h-auto inline-flex items-center justify-center rounded-full bg-[#0891B2] py-3 font-medium'
          >
            Kapcsolat
          </Link> */
  }

  const renderEmailSentPopup = () => {
    return (
      <Transition appear show={isOpenModalEmailSent} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalEmailSent}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full'>
                <div className='inline-flex flex-col text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      E-mail sikeresen elküldve
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalEmailSent} />
                    </span>
                  </div>
                  <div className='overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <div className='sm:p-0 p-12'>
                      {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'> */}
                      <div className=' w-full flex flex-col rounded-2xl dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 p-2 sm:p-4 xl:p-8'>
                        {'Hamarosan felveszzük Önnel a kapcsolatot.'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className='listingSection__wrap'>
        {/* HEADING */}
        <div>
          <h2 className='text-2xl font-semibold'>További információ </h2>
          {/* <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
            Prices may increase on weekends or holidays
          </span> */}
        </div>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
        {/* CONTENT */}
        <div className='flow-root'>
          <div className='text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4'>
            <div className='p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg'>
              <span>Éves áramfogyasztás kWh-ban</span>
              <span>1234 kWh</span>
            </div>
            {property.view && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Kilátás</span> <span>{property.view}</span>
              </div>
            )}
            {property.parking && (
              <div className='p-4 flex justify-between items-center space-x-4 rounded-lg'>
                <span>Parkolás</span> <span>{property.parking}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // const renderSectionCheckIndate = () => {
  //   return (
  //     <div className='listingSection__wrap overflow-hidden'>
  //       {/* HEADING */}
  //       <div>
  //         <h2 className='text-2xl font-semibold'>Availability</h2>
  //         <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
  //       {/* CONTENT */}

  //       <div className='listingSection__wrap__DayPickerRangeController flow-root'>
  //         <div className='-mx-4 sm:mx-auto xl:mx-[-22px]'>
  //           <DayPickerRangeController
  //             startDate={selectedDate.startDate}
  //             endDate={selectedDate.endDate}
  //             onDatesChange={(date) => setSelectedDate(date)}
  //             focusedInput={focusedInputSectionCheckDate}
  //             onFocusChange={(focusedInput) =>
  //               setFocusedInputSectionCheckDate(focusedInput || 'startDate')
  //             }
  //             initialVisibleMonth={null}
  //             numberOfMonths={windowSize.width < 1280 ? 1 : 2}
  //             daySize={getDaySize()}
  //             hideKeyboardShortcutsPanel={false}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection5 = () => {
  //   return (
  //     <div className='listingSection__wrap'>
  //       {/* HEADING */}
  //       <h2 className='text-2xl font-semibold'>Host Information</h2>
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

  //       {/* host */}
  //       <div className='flex items-center space-x-4'>
  //         <Avatar
  //           hasChecked
  //           hasCheckedClass='w-4 h-4 -top-0.5 right-0.5'
  //           sizeClass='h-14 w-14'
  //           radius='rounded-full'
  //         />
  //         <div>
  //           <a className='block text-xl font-medium' href='##'>
  //             Kevin Francis
  //           </a>
  //           <div className='mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400'>
  //             <StartRating />
  //             <span className='mx-2'>·</span>
  //             <span> 12 places</span>
  //           </div>
  //         </div>
  //       </div>

  //       {/* desc */}
  //       <span className='block text-neutral-6000 dark:text-neutral-300'>
  //         Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
  //         accommodation, an outdoor swimming pool, a bar, a shared lounge, a
  //         garden and barbecue facilities...
  //       </span>

  //       {/* info */}
  //       <div className='block text-neutral-500 dark:text-neutral-400 space-y-2.5'>
  //         <div className='flex items-center space-x-3'>
  //           <svg
  //             xmlns='http://www.w3.org/2000/svg'
  //             className='h-6 w-6'
  //             fill='none'
  //             viewBox='0 0 24 24'
  //             stroke='currentColor'
  //           >
  //             <path
  //               strokeLinecap='round'
  //               strokeLinejoin='round'
  //               strokeWidth={1.5}
  //               d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
  //             />
  //           </svg>
  //           <span>Joined in March 2016</span>
  //         </div>
  //         <div className='flex items-center space-x-3'>
  //           <svg
  //             xmlns='http://www.w3.org/2000/svg'
  //             className='h-6 w-6'
  //             fill='none'
  //             viewBox='0 0 24 24'
  //             stroke='currentColor'
  //           >
  //             <path
  //               strokeLinecap='round'
  //               strokeLinejoin='round'
  //               strokeWidth={1.5}
  //               d='M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z'
  //             />
  //           </svg>
  //           <span>Response rate - 100%</span>
  //         </div>
  //         <div className='flex items-center space-x-3'>
  //           <svg
  //             xmlns='http://www.w3.org/2000/svg'
  //             className='h-6 w-6'
  //             fill='none'
  //             viewBox='0 0 24 24'
  //             stroke='currentColor'
  //           >
  //             <path
  //               strokeLinecap='round'
  //               strokeLinejoin='round'
  //               strokeWidth={1.5}
  //               d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
  //             />
  //           </svg>

  //           <span>Fast response - within a few hours</span>
  //         </div>
  //       </div>

  //       {/* == */}
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
  //       <div>
  //         <ButtonSecondary href='##'>See host profile</ButtonSecondary>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection6 = () => {
  //   return (
  //     <div className='listingSection__wrap'>
  //       {/* HEADING */}
  //       <h2 className='text-2xl font-semibold'>Reviews (23 reviews)</h2>
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

  //       {/* Content */}
  //       <div className='space-y-5'>
  //         <FiveStartIconForRate iconClass='w-6 h-6' className='space-x-0.5' />
  //         <div className='relative'>
  //           <Input
  //             fontClass=''
  //             sizeClass='h-16 px-4 py-3'
  //             rounded='rounded-3xl'
  //             placeholder='Share your thoughts ...'
  //           />
  //           <ButtonCircle
  //             className='absolute right-2 top-1/2 transform -translate-y-1/2'
  //             size=' w-12 h-12 '
  //           >
  //             <ArrowRightIcon className='w-5 h-5' />
  //           </ButtonCircle>
  //         </div>
  //       </div>

  //       {/* comment */}
  //       <div className='divide-y divide-neutral-100 dark:divide-neutral-800'>
  //         <CommentListing className='py-8' />
  //         <CommentListing className='py-8' />
  //         <CommentListing className='py-8' />
  //         <CommentListing className='py-8' />
  //         <div className='pt-8'>
  //           <ButtonSecondary>View more 20 reviews</ButtonSecondary>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // console.log('---property---');
  // console.log(property);
  // console.log(property.latitude);
  // console.log(property.longitude);

  const renderSectionMap = () => {
    return (
      <div className='listingSection__wrap'>
        {/* HEADING */}
        <div>
          <h2 className='text-2xl font-semibold'>Elhelyezkedés</h2>
          <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
            {property.address}
          </span>
        </div>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

        {/* MAP */}
        <div className='aspect-w-5 aspect-h-5 sm:aspect-h-3'>
          <div className='rounded-xl overflow-hidden'>
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY',
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: 55.9607277,
                lng: 36.2172614,
              }}
            >
              <LocationMarker lat={55.9607277} lng={36.2172614} />
            </GoogleMapReact> */}
            {/* <div style={'width: 100%'> */}
            {/* <iframe
                width='100%'
                height='600'
                frameborder='0'
                scrolling='no'
                marginheight='0'
                marginwidth='0'
                src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&amp;hl=en&amp;z=14&amp;output=embed`}
              >
                <a href='https://www.maps.ie/distance-area-calculator.html'>
                  area maps
                </a>
              </iframe> */}
            <div className='w-full h-full'>
              <iframe
                className='w-full h-full aspect-video'
                // className='ev-map'
                //height='470'
                src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&hl=en&z=14&output=embed`}
              ></iframe>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSectionVirtualTour = () => {
    if (property.v360) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Virtuális séta</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
          <iframe
            src={property.v360}
            width='100%'
            height='500'
            allowFullScreen='true'
          ></iframe>
        </div>
      );
    }
  };

  // <?php if(!empty($itemDetails['pt_video'])): ?>
  // <h5 class="uk-heading-line uk-text-bold"><span><?php echo echoOutput($translation['tr_72']); ?></span></h5>
  // <button class="uk-button uk-button-default uk-width-1-1 uk-border-rounded uk-margin" type="button" uk-toggle="target: +"><i class="fas fa-play uk-margin-small-right"></i> <?php echo echoOutput($translation['tr_74']); ?></button>
  // <iframe src="https://www.youtube-nocookie.com/embed/<?php echo echoOutput($itemDetails['pt_video']); ?>?autoplay=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=0&amp;playsinline=1" width="1920" height="1080" frameborder="0" allowfullscreen uk-responsive uk-video="automute: false" hidden></iframe>
  // <?php endif; ?>

  const renderSectionYoutubeEmbed = () => {
    if (property.video_url) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Videó bemutató</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${property.video_url}`}
            width='100%'
            height='500'
            allowfullscreen='true'
          ></iframe>
        </div>
      );
    }
  };

  const renderSectionTiktokEmbed = () => {
    if (property.tiktok_id) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Tiktok videó</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />
          <iframe
            src={`https://www.tiktok.com/embed/v2/${property.tiktok_id}`}
            width='100%'
            height='740'
            allowfullscreen='true'
          ></iframe>
          {/* {getTiktokEmbedHTML()} */}
        </div>
      );
    }
  };

  // const renderSection8 = () => {
  //   return (
  //     <div className='listingSection__wrap'>
  //       {/* HEADING */}
  //       <h2 className='text-2xl font-semibold'>Things to know</h2>
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className='text-lg font-semibold'>Cancellation policy</h4>
  //         <span className='block mt-3 text-neutral-500 dark:text-neutral-400'>
  //           Refund 50% of the booking value when customers cancel the room
  //           within 48 hours after successful booking and 14 days before the
  //           check-in time. <br />
  //           Then, cancel the room 14 days before the check-in time, get a 50%
  //           refund of the total amount paid (minus the service fee).
  //         </span>
  //       </div>
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className='text-lg font-semibold'>Check-in time</h4>
  //         <div className='mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base'>
  //           <div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
  //             <span>Check-in</span>
  //             <span>08:00 am - 12:00 am</span>
  //           </div>
  //           <div className='flex space-x-10 justify-between p-3'>
  //             <span>Check-out</span>
  //             <span>02:00 pm - 04:00 pm</span>
  //           </div>
  //         </div>
  //       </div>
  //       <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

  //       {/* CONTENT */}
  //       <div>
  //         <h4 className='text-lg font-semibold'>Special Note</h4>
  //         <div className='prose sm:prose'>
  //           <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2'>
  //             <li>
  //               Ban and I will work together to keep the landscape and
  //               environment green and clean by not littering, not using
  //               stimulants and respecting people around.
  //             </li>
  //             <li>Do not sing karaoke past 11:30</li>
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSectionSimilarProperties = () => {
    return (
      <>
        {relatedProperties && relatedProperties.length !== 0 && (
          <>
            {/* <div className='listingSection__wrap !space-y-6 mb-12'> */}
            <div className='w-full flex flex-col sm:rounded-2xl space-y-6 sm:space-y-8 sm:mb-24 xl:mb-24 px-0 sm:p-4 xl:p-8 !space-y-6 mb-12'>
              {/* <div>
                <h2 className='text-2xl font-semibold'>Önnek ajánljuk</h2>
              </div> */}
              {/*
              <div className='grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 '>
                {relatedProperties.map((item) => (
                  <PropertyCardH key={item.id} data={item} />
                ))}
              </div>
              {/* <div className='w-full border-b border-neutral-100 dark:border-neutral-700' /> */}
              {/* </div> }
            </div> */}
              {/* <div
              className={`nc-SectionGridFilterCard ${className}`}
              data-nc-id='SectionGridFilterCard'
            > */}

              {/* <div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {relatedProperties.map((property) => (
                  <>
                    <StayCard key={property.id} data={property} />
                    <StayCard key={property.id} data={property} />
                    <StayCard key={property.id} data={property} />
                    <StayCard key={property.id} data={property} />
                    <StayCard key={property.id} data={property} />
                  </>
                ))}
              </div> */}
              {/* <SectionSliderNewCategories
                categories={DEMO_CATS}
                uniqueClassName='PageHome_s1'
              /> */}
              {/* <BackgroundSection className='bg-sky-100' /> */}
              {/* <BackgroundSection className=' bg-[#F3F5FF]' /> */}
              {/* <BackgroundSection className=' bg-[#0891B2]/20' /> */}
              <BackgroundSection className=' bg-neutral-100' />

              {/* <BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 ' /> */}
              <SectionRelatedSlider
                property={relatedProperties}
                uniqueClassName='PageHome_s1'
                categoryCardType='card4'
                itemPerRow={4}
                sliderStyle='style1'
                uniqueClassName='PageHome_s2'
              />
            </div>
          </>
        )}
      </>
    );
  };

  const renderSidebar = () => {
    return (
      <div className='sm:p-0 p-0'>
        <div className='listingSectionSidebar__wrap shadow-xl mb-24 p-8'>
          {/* <div className='listingSectionSidebar__wrap shadow-xl mb-24 sm:w-full w-4/5'> */}
          <Avatar sizeClass='h-36 w-36 self-center' radius='rounded-full' />
          <div className='flex justify-between  '>
            <span> Az ön ingatlan értékesítője</span>
            <span> Kiss Péter</span>
          </div>
          <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='flex justify-between '>
            <span>Ár HUF</span>
            <span>{property.price}</span>
            {property.statusId === 2 && (
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /hó
              </span>
            )}
          </div>
          <div className='flex justify-between text-bold'>
            <span>Ár EUR</span>
            <span className='semi-bold'>{priceInEur}</span>
            {property.statusId === 2 && (
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /hó
              </span>
            )}
          </div>
          {/* SUBMIT */}

          {/* <a className='' href={`${appConfig.URL}contact-us-3`} target='_blank'>
            <ButtonPrimary>Kapcsolat</ButtonPrimary>
          </a> */}
          {/* <Link
            to={`${appConfig.URL}contact-us-3`}
            className='nc-Button text-neutral-50 relative h-auto inline-flex items-center justify-center rounded-full bg-[#0891B2] py-3 font-medium'
          >
            Kapcsolat
          </Link> */}

          <ButtonPrimary onClick={openModalAmenities}>Kapcsolat</ButtonPrimary>
        </div>

        {/* <div className='min-h-screen text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. *
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            className='inline-block py-8 h-screen w-full'
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
              <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  További szűrés
                </Dialog.Title>
                <span className='absolute left-3 top-3'>
                  <ButtonClose onClick={closeModalMoreFilter} />
                </span>
              </div>

              <div className='flex-grow overflow-y-auto'>
                <div className='px-10 divide-y divide-neutral-200 dark:divide-neutral-800'></div>
              </div>

              <div className='p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
                <ButtonThird
                  onClick={() => {
                    //closeModalMoreFilterOnClear();
                  }}
                  sizeClass='px-4 py-2 sm:px-5'
                >
                  Szűrők Törlése
                </ButtonThird>
                <ButtonPrimary
                  onClick={closeModalMoreFilter}
                  sizeClass='px-4 py-2 sm:px-5'
                >
                  Szűrés
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div> */}
      </div>
      //     </Dialog>
      //   </Transition>
      // </div>

      // </div>
    );
  };

  return property[0] !== false ? (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id='ListingStayDetailPage'
    >
      {/* SINGLE HEADER */}
      {/* <>
        <header className='container 2xl:px-14 rounded-md sm:rounded-xl'>
          <div className='relative grid grid-cols-3 sm:grid-cols-4 gap-4 sm:gap-2'>
            <div
              className='col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName='absolute inset-0'
                className='object-cover w-full h-full rounded-md sm:rounded-xl'
                src={property.image}
              />
              <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
            </div> 
            {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? 'hidden sm:block' : ''
                }`}
              >
                <NcImage
                  containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                  className='object-cover w-full h-full rounded-md sm:rounded-xl '
                  src={item || ''}
                />

              
                <div
                  className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                />
              </svg>
              <span className='ml-2 text-neutral-800 text-sm font-medium'>
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
      {/* <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName='nc-ListingStayDetailPage-modalPhotos'
        />
              </> */}

      {/* {
        (console.log('Properties count: '),
        console.log(images.length),
        console.log(images.length == 1),
        console.log(images.length >= 2),
        console.log(images.length != 1))
      } */}
      {images.length == 1 && (
        <header className='container 2xl:px-56 rounded-md sm:rounded-xl'>
          <div className='relative z-10 mt-11 flex flex-col lg:flex-row '>
            <div
              className=' rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName='absolute inset-0'
                className='object-cover w-full h-full rounded-md sm:rounded-xl'
                src={property.image}
              />
              <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
            </div>
            {/* Give some space */}
            <div className='sm:h-[600px] h-[300px]'></div>

            <div
              className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                />
              </svg>
              <span className='ml-2 text-neutral-800 text-sm font-medium'>
                Galéria megtekintése
              </span>
            </div>
          </div>
        </header>
      )}
      {images.length >= 2 && (
        <>
          <header className='container 2xl:px-14 rounded-md sm:rounded-xl'>
            <div className='relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2'>
              <div
                className='sm:col-span-3 col-span-2 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  containerClassName='absolute inset-0'
                  className='object-cover w-full h-full rounded-md sm:rounded-xl'
                  src={property.image}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {images &&
                images.length > 1 &&
                images
                  .filter((_, i) => i >= 1 && i < 4)
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                        index >= 2 ? 'block' : ''
                      }`}
                    >
                      <NcImage
                        containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                        className='object-cover w-full h-full rounded-md sm:rounded-xl '
                        src={item}
                      />

                      <div
                        className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                        onClick={() => handleOpenModal(index + 1)}
                      />
                    </div>
                  ))}
              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Galéria megtekintése
                </span>
              </div>
            </div>
          </header>
        </>
      )}
      {/* MODAL PHOTOS */}
      <ModalPhotos
        imgs={images}
        isOpen={isOpen}
        onClose={handleCloseModal}
        initFocus={openFocusIndex}
        uniqueClassName='nc-ListingExperiencesDetailPage__modalPhotos'
      />

      {/* MAIN */}
      <main className='container relative z-10 mt-6 sm:mt-11 flex flex-col lg:flex-row '>
        {/* CONTENT */}
        <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
          {renderSectionMainCard()}
          {renderSectionDescription()}
          {/* {renderSectionExtras()} */}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()}
          {renderSection6()} */}
          {renderSectionMap()}
          {renderSectionVirtualTour()}
          {renderSectionYoutubeEmbed()}
          {renderSectionTiktokEmbed()}
          {renderContact()}
          {renderEmailSentPopup()}
        </div>

        {/* SIDEBAR */}
        <div className='block flex-grow mt-14 lg:mt-0'>
          <div className='sticky top-24'>{renderSidebar()}</div>
        </div>
      </main>

      <div className='container relative z-10 mt-11 mb-12 flex flex-col lg:flex-row'>
        {renderSectionSimilarProperties()}
      </div>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && (
        <div className='block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20'>
          <div className='container flex items-center justify-between'>
            {/* <span className='text-2xl font-semibold'>
              $311
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /night
              </span>
            </span> */}
            <span className='text-2xl font-semibold'>
              {property.price}
              {/* IF APARTMENT */}
              {property.statusId === 2 && (
                <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                  /hó
                </span>
              )}
            </span>
            {/* <a href={`${appConfig.URL}contact-us-3`}> */}
            <ButtonPrimary onClick={openModalAmenities}>
              Kapcsolat
            </ButtonPrimary>
            {/* </a> */}
          </div>
        </div>
      )}

      {/* OTHER SECTION */}
      {/* {!isPreviewMode && (
        <div className='container py-24 lg:py-32'>
     
          <div className='relative py-16'>
            <BackgroundSection />
            <SectionSliderNewCategories
              heading='Explore by types of stays'
              subHeading='Explore houses based on 10 types of stays'
              categoryCardType='card5'
              itemPerRow={5}
              sliderStyle='style2'
              uniqueClassName={'ListingStayDetailPage1'}
            />
          </div>

          <SectionSubscribe2 className='pt-24 lg:pt-32' />
        </div>
      )} */}
    </div>
  ) : (
    <Page404 />
  );
};

export default ListingStayDetailPage;
