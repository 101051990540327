import React, { FC } from 'react';

export interface CheckboxProps {
  name?: string;
  label?: string;
  value?: boolean;
  onChange?: any;
}

const Checkbox: FC<CheckboxProps> = ({
  name = '',
  label = '',
  value,
  onChange,
}) => {
  return (
    <div className='flex text-sm sm:text-base'>
      <input
        id={name}
        name={name}
        type='checkbox'
        checked={value}
        onChange={(e) => onChange && onChange(e.target.checked)}
        className='focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500'
      />
      {label && (
        <label
          htmlFor={name}
          className='ml-3.5 flex flex-col flex-1 justify-center'
        >
          <span className='text-neutral-900 dark:text-neutral-100'>
            {label}
          </span>
        </label>
      )}
    </div>
  );
};

export default Checkbox;
