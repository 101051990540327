import React, { FC, useEffect, useMemo } from 'react';
import Heading from 'components/Heading/Heading';
import Glide from '@glidejs/glide';
import { TaxonomyType } from 'data/types';
import CardCategory3 from 'components/CardCategory3/CardCategory3';
import CardCategory4 from 'components/CardCategory4/CardCategory4';
import NextPrev from 'shared/NextPrev/NextPrev';
import CardCategory5 from 'components/CardCategory5/CardCategory5';
import StayCard from 'components/StayCard/StayCard';
import useNcId from 'hooks/useNcId';

export interface SectionRelatedSliderProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  property?: object[];
  categoryCardType?: 'card3' | 'card4' | 'card5';
  itemPerRow?: 4 | 5;
  sliderStyle?: 'style1' | 'style2';
  uniqueClassName: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: '1',
    href: '/listing-stay',
    name: 'Nature House',
    taxonomy: 'category',
    count: 17288,
    thumbnail:
      'https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  },
];

const SectionRelatedSlider: FC<SectionRelatedSliderProps> = ({
  heading = 'Önnek ajánljuk',
  subHeading = '',
  className = '',
  itemClassName = '',
  property = DEMO_CATS,
  itemPerRow = 4,
  //categoryCardType = 'card3',
  sliderStyle = 'style1',
  uniqueClassName,
}) => {
  const UNIQUE_CLASS =
    'SectionSliderNewproperty__' + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  //   const renderCard = (item: TaxonomyType, index: number) => {
  //     <StayCard key={property.id} data={property} />;
  //   };

  return (
    <div className={`nc-SectionSliderNewproperty ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === 'style1'}
          isCenter={sliderStyle === 'style2'}
        >
          <span className='text-3xl'> {heading}</span>
        </Heading>
        <div className='glide__track' data-glide-el='track'>
          <ul className='glide__slides'>
            {property.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {/* {renderCard(item, index)} */}
                {/* @ts-ignore */}
                <StayCard key={item.id} data={item} />
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === 'style2' && (
          <NextPrev className='justify-center mt-16' />
        )}
      </div>
    </div>
  );
};

export default SectionRelatedSlider;
