import React from 'react';
import { ReactNode } from 'react';

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = '',
  heading = 'Stays in Tokyo',
  subHeading,
}) => {
  return (
    <div className={`${className} mb-12 lg:mb-16 `}>
      <h2 className='text-2xl sm:text-4xl font-normal sm:font-semibold'>
        {heading}
      </h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className='block text-neutral-500 dark:text-neutral-400 mt-3'>
          233 stays
          <span className='mx-2'>·</span>
          Aug 12 - 20
          <span className='mx-2'>·</span>2 Guests
        </span>
      )}
    </div>
  );
};

export default Heading2;
