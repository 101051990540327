import React, { FC } from 'react';
//import imagePng from 'images/hero-right-3.png';
// import imagePng from 'images/platten fokep-min.jpg';
//import imagePng from 'images/hero-right-d.png';
// import imagePng from 'images/proba.png';
import imagePng from 'images/hero-right-d-min.png';
import HeroRealEstateSearchForm from 'components/HeroSearchForm/HeroRealEstateSearchForm';
import HeroSearchMobile from 'components/HeroSearchMobile/HeroSearchMobile';
import useWindowSize from 'hooks/useWindowResize';

export interface SectionHero2ArchivePageProps {
  className?: string;
  children?: React.ReactNode;
}

const SectionHero2ArchivePage: FC<SectionHero2ArchivePageProps> = ({
  className = '',
  children,
}) => {
  const windowSize = useWindowSize();
  const mobileMinWidthBreakpoint = 768;

  const renderMobileHeroSearch = () => {
    return (
      <button
        // @ts-ignore
        onClick={console.log('')}
        className='relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg'
      >
        {/* <MagnifyingGlassIcon className='flex-shrink-0 w-5 h-5' /> */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
          />
        </svg>

        <div className='ml-3 flex-1 text-left overflow-hidden'>
          <span className='block font-medium text-sm'>Keresés</span>
          <span className='block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 line-clamp-1'>
            {/* Anywhere • Any week • Add guests */}
            Balatoni Ingatlanok
          </span>
        </div>

        <span className='absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300'>
          <svg
            viewBox='0 0 16 16'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            className='block w-4 h-4'
            fill='currentColor'
          >
            <path d='M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z'></path>
          </svg>
        </span>
      </button>
    );
  };

  return (
    /* <div
        className={`nc-SectionHero2ArchivePage relative ${className}`}
        data-nc-id='SectionHero2ArchivePage'
      >
        <div className='absolute bottom-0 top-60 md:inset-y-0 w-5/6 xl:w-3/4 right-0 flex-grow'>
          <img
            className='absolute inset-0 object-cover w-full h-full'
            src={imagePng}
            alt='hero'
          />
        </div>
        <div className='relative flex flex-col-reverse items-start md:block pb-14 md:py-14 '>
          <div className='relative inline-flex'>
            <div className='w-screen right-10 md:right-32 inset-y-0 absolute'></div>
            <div className='relative max-w-3xl inline-flex flex-shrink-0 flex-col items-start py-16 sm:py-20 space-y-8 sm:space-y-10 text-black'>
              <h2 className='hidden md:block font-medium text-4xl md:text-5xl xl:text-6xl leading-[110%]'>
                Balatoni Ingatlanok
              </h2>
              <div className='flex items-center text-base md:text-lg '>
                <i className='hidden md:block text-2xl las la-map-marked'></i>
                <span className='hidden md:block hidden md:block ml-2.5'>
                  Magyarország
                </span>
                <span className='mx-5'></span>
                <i className='hidden md:block text-2xl las la-home'></i>
                <span className='hidden md:block ml-2.5'>100+ ingatlan</span>
              </div>
            </div>
          </div>
          <div className='mb-10 md:mb-0 md:mt-10 w-full'>
            <HeroRealEstateSearchForm />
          </div>
        </div>
      </div> */

    <div
      className={`nc-SectionHero flex flex-col lg:flex-col relative ${className}`}
      data-nc-id='SectionHero'
    >
      <div className='flex flex-col lg:flex-row lg:items-center'>
        <div className='flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-4 sm:space-y-10 pb-0 sm:pb-14 lg:pb-12 xl:mr-0'>
          {/* <div className='flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 xl:mr-0'> */}
          <h2 className='hidden sm:block font-medium text-4xl md:text-5xl xl:text-6xl !leading-[114%] '>
            Balatoni Ingatlanok
          </h2>
          <div className='flex items-center text-base md:text-lg '>
            <i className='hidden md:block text-2xl las la-map-marked'></i>
            <span className='hidden md:block hidden md:block ml-2.5'>
              Magyarország
            </span>
            <span className='mx-5'></span>
            <i className='hidden md:block text-2xl las la-home'></i>
            <span className='hidden md:block ml-2.5'>100+ ingatlan</span>
          </div>
        </div>
        <div className='flex-grow'>
          <img className='w-full' src={imagePng} alt='hero' />
        </div>
        <h2 className='block sm:hidden font-semibold text-4xl mt-10 !leading-[114%] '>
          Balatoni Ingatlanok
        </h2>
        {/* */}
        {/* {renderButtonOpenModal()} */}

        {/* */}
      </div>

      <div className='z-10 mb-12 lg:mb-0 lg:-mt-40 w-full'>
        <HeroRealEstateSearchForm />
      </div>

      {/* {mobileMinWidthBreakpoint <= windowSize.width ? (
        <>
          <div className='z-10 mb-12 lg:mb-0 lg:-mt-40 w-full'>
            <HeroRealEstateSearchForm />
          </div>
        </>
      ) : (
        // <>{renderMobileHeroSearch()}</>
        <></>
      )} */}
      {/* <div className='z-10 mb-12 lg:mb-0 lg:-mt-40 w-full'>
        <HeroRealEstateSearchForm />
      </div> */}
    </div>
  );
};

export default SectionHero2ArchivePage;
