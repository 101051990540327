import React, { FC, useState, useEffect } from 'react';
// import Pagination from 'shared/Pagination/Pagination';
import TabFilters from './TabFilters';
import Heading2 from 'components/Heading/Heading2';
import PropertyCardH from 'components/PropertyCardH/PropertyCardH';
import { getFeaturedProperties } from 'data/DataController';

export interface SectionGridFilterCardProps {
  className?: string;
}

// @ts-ignore
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
}) => {
  const [featuredProperties, setFeaturedProperties] = useState([]);

  useEffect(() => {
    getFeaturedProperties('hu').then((response) => {
      setFeaturedProperties(response);
    });
  }, []);

  return (
    featuredProperties && (
      <div
        className={`nc-SectionGridFilterCard ${className}`}
        data-nc-id='SectionGridFilterCard'
      >
        <Heading2 heading='Kiemelt ingatlanjaink' subHeading=' ' />

        <div className='grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 '>
          {
            // @ts-ignore
            featuredProperties.map((stay) => (
              // @ts-ignore
              <PropertyCardH key={stay.id} data={stay} showCity={true} />
            ))
          }
        </div>
      </div>
    )
  );
};

export default SectionGridFilterCard;
