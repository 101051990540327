// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import Checkbox from 'shared/Checkbox/Checkbox';

// DEMO DATA
export interface PropertyType {
  name: string;
  description: string;
  checked: boolean;
}

export interface PropertyTypeSelectProps {
  onChange?: (arg0: string, arg1: Array<number>) => void;
  //defaultValue?: string;
  types?: Array<any>;
}

const PropertyTypeSelect: FC<PropertyTypeSelectProps> = ({
  onChange,
  //defaultValue,
  types,
}) => {
  // const [typeOfProperty, setTypeOfProperty] = React.useState<PropertyType[]>(
  //   defaultValue || []
  // );
  const [value, setValue] = useState('');
  const [activePropertyType, setActivePropertyType] = useState();
  const [activePropertyTypeText, setActivePropertyTypeText] = useState();

  // useEffect(() => {
  //   if (!defaultValue) return;
  //   setTypeOfProperty(defaultValue);
  // }, [defaultValue]);

  const handleSelectLocation = (item: Array<any>) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item.title);
      onChange && onChange(item.title, item.id);
    }, 0);
  };

  return (
    <div className='p-5'>
      <span className='block font-semibold text-xl sm:text-2xl'>
        Ingatlan típusa
      </span>
      <div className='flex flex-col'>
        {/* <p className='block font-semibold text-base'>{'Destinations'}</p> */}
        <div className='mt-3'>
          {types &&
            types?.map((item) => {
              return (
                <a
                  key={item.title}
                  onClick={(e) => {
                    e.preventDefault();
                    setActivePropertyType(item.id);
                    handleSelectLocation(item);

                    //setOrderByState(item.title);
                    //close();
                  }}
                  className=''
                >
                  <div
                    className='py-2 mb-1 flex items-center space-x-3 text-sm'
                    // onClick={() => handleSelectLocation(item)}
                    key={item.id}
                  >
                    {/* <MapPinIcon className='w-5 h-5 text-neutral-500 dark:text-neutral-400' /> */}

                    <span className=''>{item.title}</span>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PropertyTypeSelect;
