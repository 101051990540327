import React, { FC, useState } from 'react';

export interface ButtonSearchProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  onPress?: any;
}

const ButtonSearch: FC<ButtonSearchProps> = ({
  className = '',
  colorClass = 'text-white bg-black bg-opacity-30 hover:bg-opacity-50',
  isLiked = false,
  onPress,
}) => {
  const [likedState, setLikedState] = useState(isLiked);

  return (
    <div
      className='h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none'
      data-nc-id='BtnSearchIcon'
      title='Save'
      // onClick={() => setLikedState(!likedState)}
      onClick={() => onPress()}
      style={{ cursor: 'pointer' }}
    >
      <span className='mr-3 md:hidden'>Search</span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={1.5}
          d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
        />
      </svg>
    </div>
  );
};

export default ButtonSearch;
