import React, { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { FC } from 'react';
import Checkbox from 'shared/Checkbox/Checkbox';

export interface PropertyTypeSelectProps {
  onChange?: (arg0: string, arg1: Array<number>) => void;
  fieldClassName?: string;
  types?: Array<any>;
  defaultValue: string;
  //   Provider {
  //     id: number,
  //     title: string,
  // }
}

const PropertyTypeSelect: FC<PropertyTypeSelectProps> = ({
  onChange,
  fieldClassName = '[ nc-hero-field-padding ]',
  types,
  defaultValue,
}) => {
  //console.log(types?.map(({ id }) => id));
  // const [activePropertyTypes, setActivePropertyTypes] = useState(
  //   types?.map(({ id }) => id)
  // );
  // const [typeOfProperty, setTypeOfProperty] = ();
  const [activePropertyType, setActivePropertyType] = useState();
  const [activePropertyTypeText, setActivePropertyTypeText] = useState();

  // console.log('Active property types: ');
  // console.log(activePropertyType);

  // console.log('Rendering with types: ');
  // console.log(types);

  // useEffect(() => {
  //   onChange(activePropertyType);
  // }, [activePropertyType]);

  // useEffect(() => {
  //   if (activePropertyType && types) {
  //     setActivePropertyTypeText(
  //       types.find((type) => type.id === activePropertyType).title
  //     );
  //   }
  // }, [activePropertyType]);

  // useEffect(() => {
  //   if (!defaultValue) return;
  //   setTypeOfProperty(defaultValue);
  // }, defaultValue);

  //activePropertyTypes.forEach((element) => types[0]['name']);
  // console.log('<-------------------------------->');
  // console.log(activePropertyTypes);
  // console.log(types);

  //array.find(x => x.name === 'string 1')

  // console.log('Types: ');
  // console.log(activePropertyTypes);

  // console.log('Check default checked');
  // console.log(
  //   // types?.map((item) => console.log(item.id))
  //   activePropertyTypes?.filter(
  //     /* @ts-ignore */
  //     (item) => console.log(item)
  //   )
  // );

  return (
    <Popover className='flex relative [ nc-flex-0 ]'>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? 'nc-hero-field-focused' : ''
            }`}
          >
            <div className='text-neutral-300 dark:text-neutral-400'>
              <svg
                className='nc-icon-field nc-icon-field-2'
                width='24'
                height='24'
                fill='none'
                viewBox='0 0 24 24'
              >
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                  d='M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V9.75025L12.0002 4.75024L4.75024 9.75025V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z'
                ></path>
                <path
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                  d='M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z'
                ></path>
              </svg>
            </div>
            <div className='flex-1'>
              <span className='block xl:text-lg font-semibold overflow-hidden'>
                <span className='line-clamp-1'>{defaultValue || 'Típus'}</span>
              </span>
              <span className='block mt-1 text-sm text-neutral-400 leading-none font-light '>
                Ingatlan típusa
              </span>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute left-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl'>
              <div className=''>
                <div className='relative flex flex-col'>
                  {/* {types?.map((item, index: number) => (
                    <div key={item.id} className=''></div>
                  ))} */}
                  {types?.map((item) => (
                    <a
                      key={item.title}
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePropertyType(item.id);
                        // @ts-ignore
                        onChange(item.title, item.id);
                        //setOrderByState(item.title);
                        close();
                      }}
                      className='flex items-center p-2 -m-3 mb-5 last:mb-0 ml-1 sm:ml-0 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                    >
                      <p className='font-medium text-neutral-700 dark:text-neutral-20'>
                        {item.title}
                      </p>
                    </a>
                  ))}
                  {/* {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
              onClick={() => setTabActive(tab)}
              key={tab}
          );
        })} */}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default PropertyTypeSelect;
